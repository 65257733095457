import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Meta } from '@angular/platform-browser';
import { UserService } from '../services/user.service';
import { CognitoService } from '../services/cognito.service';
import { AssessmentService } from '../services/assessment.service';

const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control && control.value) {
    if (!control.value.trim()) {
      return { required: true };
    }
  }
  return null;
};

@Component({
  selector: 'app-yogaday',
  templateUrl: './yogaday.component.html',
  styleUrls: ['./yogaday.component.scss'],
  providers: [NgbCarouselConfig],
})
export class YogadayComponent implements AfterViewInit, OnInit {
  // @ViewChild('target') register: any;
  @ViewChild("register", { static: true }) register: ElementRef;
  closeResult = '';
  registerForm: any;
  screen = "register";
  showNavigationArrows = false;
  showNavigationIndicators = false;

  constructor(private httpClient: HttpClient,
    private modalService: NgbModal,
    private loaderSvc: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private userSvc: UserService,
    private readonly assessmentSvc: AssessmentService,
    private cognitoSvc: CognitoService,
    private meta: Meta,
    config: NgbCarouselConfig) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;

  }

  ngOnInit(): void {
    this.meta.updateTag({ property: 'og:title', content: 'International Day of Yoga 2024, Johannesburg' });
    // this.meta.updateTag({ property: 'og:url', content: 'https://zaf.phylaxis.ai/yogaforlife' });
    this.meta.updateTag({ property: 'og:image', content: 'https://prod-phylaxis-media-files.s3.eu-west-3.amazonaws.com/img/yoga_meta_logo.png' });
    // this.meta.updateTag({ property: 'og:description', content: 'First of its kind for promoting Yoga awareness at scale through combining established Yoga science and evolving data-science.' });

  }

  ngAfterViewInit(): void {
    this.modalService.dismissAll();
    this.registerForm = this.fb.group({
      eventCode: new FormControl('IDY_JHB_2024'),
      // userName: new FormControl(null, [Validators.required, trimValidator, Validators.maxLength(100)]),
      userPhoneNumber: new FormControl(null, [trimValidator, Validators.required, Validators.pattern('[+0-9]{10,15}')]),
      userEmail: new FormControl(null, [Validators.required, trimValidator, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'), Validators.maxLength(100)]),
      vitalityPointsDisclaimer: new FormControl('Yes', [Validators.required]),
      multiplyPointsDisclaimer: new FormControl('Yes', [Validators.required]),
      comments: new FormControl(null, [Validators.maxLength(255)]),
      familyMember: this.fb.array([this.createMember()], Validators.required)
    });

    this.activatedRoute.queryParams.subscribe(response => {
      console.log(response);
      if (response && response['openRegistration'] && response['openRegistration'] == 'true') {
        this.open(this.register);
        console.log(response['openRegistration']);
      }
    });
    this.getAnonymousUser();
    // this.familyMember.valueChanges.subscribe(x => {
    //   this.conditionalValidator();
    // });
  }

  getAnonymousUser() {
    this.userSvc.getAnonymousUser().subscribe(response => {
      console.log(response);
      if (response.response.codes === "R00") {
        const user: any = JSON.parse(atob(response.anonymousUser));
        this.cognitoSvc.signIn({ phone_number: user.phone_number, password: user.password })
          .then((response) => {
            console.log(response);

            // this.getLanguages();
          }).catch((response) => {
            console.log(response);
            this.showError({ msg: response })
            // this.errorMessage = response.message;
            this.loaderSvc.hideLoader();

          });
      }
    });

  }

  conditionalValidator() {
    for (let i = 0; i < this.familyMember.controls.length; i++) {
      const memberControl = this.familyMember.controls[i];
        if (memberControl && memberControl.value.isMemberVitalityMember === 'Yes') {
          memberControl.get('memberVitalityId').setValidators([Validators.required, trimValidator, Validators.maxLength(15), Validators.pattern("^[0-9]*$")]);
        } else if (memberControl && memberControl.value.isMemberVitalityMember === 'No') {
          memberControl.get('memberVitalityId').clearValidators();
          memberControl.get('memberVitalityId').setValue(null);
        }
        if (memberControl && memberControl.value.isMemberMultiplyMember === 'Yes') {
          memberControl.get('memberMultiplyId').setValidators([Validators.required, trimValidator, Validators.maxLength(13), Validators.pattern("^[0-9]*$")]);
        } else if (memberControl && memberControl.value.isMemberMultiplyMember === 'No') {
          memberControl.get('memberMultiplyId').clearValidators();
          memberControl.get('memberMultiplyId').setValue(null);
        }
    }
  }

  updateValidator() {
    for (let i = 0; i < this.familyMember.controls.length; i++) {
      const memberControl = this.familyMember.controls[i];
      memberControl.get('memberVitalityId').updateValueAndValidity();
      memberControl.get('memberMultiplyId').updateValueAndValidity();
    }
  }


  createMember(): FormGroup {
    return this.fb.group({
      memberName: new FormControl(null, [Validators.required, trimValidator, Validators.maxLength(100)]),
      isMemberVitalityMember: new FormControl(null, [Validators.required]),
      memberVitalityId: new FormControl(null, [trimValidator, Validators.maxLength(15), Validators.pattern("^[0-9]*$")]),
      isMemberMultiplyMember: new FormControl(null, [Validators.required]),
      memberMultiplyId: new FormControl(null, [trimValidator, Validators.maxLength(13), Validators.pattern("^[0-9]*$")]),
      memberGender: new FormControl(null, [Validators.required]),
      memberShirtSize: new FormControl(null, [Validators.required]),
      memberId: new FormControl(null, [trimValidator, Validators.minLength(7), Validators.maxLength(20)]),
      isMemberVaMember: new FormControl(null, [Validators.required]),
      isMemberPfMember: new FormControl(null, [Validators.required]),

    });
  }



  addMember() {
    this.familyMember.push(this.createMember());
  }

  deleteMember(index: any) {
    const control = <FormArray>this.registerForm.controls['familyMember'];
    control.removeAt(index);
  }

  get familyMember(): FormArray {
    return <FormArray>this.registerForm.get('familyMember');
  }

  next(el: HTMLElement) {
    // el.scrollIntoView();
    this.conditionalValidator();
    this.updateValidator();
    this.registerForm['submitted'] = true;
    if (this.registerForm.valid) {
      this.loaderSvc.showLoader();

      // this.registerForm.value.userName = this.registerForm.value.userName.trim();
      if (this.registerForm.value.userEmail) {
        this.registerForm.value.userEmail = this.registerForm.value.userEmail.trim();
      }
      if (this.registerForm.value.userPhoneNumber) {
        this.registerForm.value.userPhoneNumber = this.registerForm.value.userPhoneNumber.trim();
      }
      this.registerForm.value.familyMember.forEach(member => {
        if (member.memberName) {
          member.memberName = member.memberName.trim();
        }
        if (member.memberId) {
          member.memberId = member.memberId.trim();
        }
        if (member.isMemberVitalityMember === 'No') {
          member.memberVitalityId = null;
        } else if (member.memberVitalityId) {
          member.memberVitalityId = member.memberVitalityId.trim();
        }
        if (member.isMemberMultiplyMember === 'No') {
          member.memberMultiplyId = null;
        } else if (member.memberVitalityId) {
          member.memberMultiplyId = member.memberMultiplyId.trim();
        }
      })
      this.recaptchaExecute();
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  back(el: HTMLElement) {
    el.scrollIntoView();
    this.screen = "register";
  }

  submitForm(el: HTMLElement) {
    el.scrollIntoView();
    this.loaderSvc.showLoader();
    if (this.registerForm.valid) {
      this.httpClient.post<any>(`${environment.baseUrlAWSApi}/yoga/register`, this.registerForm.value).subscribe((result: any) => {
        this.loaderSvc.hideLoader();
        this.screen = "complete";

      });
    }
  }

  open(content: any) {
    this.modalService
      .open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  startAssessment() {
    this.modalService.dismissAll();
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/survey/start'], {
    //   queryParams: { v: 'U2FsdGVkX1%2FUBMe%2F%2Bi0OVNNas8VxQEcvR1ruFRoqD9TlEXfgjmgAcVJ%2BtSAc%2Fgf0hJp6u7%2Fk56pKybQkP7wOMko7E5atcyY%2BqdEf5yNXVow%3D' }
    // }));
    // this.router.navigate(['survey', 'start'], {
    //   queryParams: { v: 'U2FsdGVkX1+tuMGO4NwnzsT3nJU4505lbwcKKUrne1+TVodAkvFSyXT4kwsK+ks2U9f9byECdjvcl1bVeCf0gEBl0EYBRulg2aTakKVaQHY=' }
    // });
    const link = this.router.serializeUrl(this.router.createUrlTree(['survey', 'start'], {
      queryParams: { v: 'U2FsdGVkX1+tuMGO4NwnzsT3nJU4505lbwcKKUrne1+TVodAkvFSyXT4kwsK+ks2U9f9byECdjvcl1bVeCf0gEBl0EYBRulg2aTakKVaQHY=' }

    }));
    window.open(link, '_blank');
  }

  recaptchaExecute() {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        console.log(`Token [${token}] generated`);
        this.screen = "review";
        this.loaderSvc.hideLoader();
      });
  }

  private scrollToFirstInvalidControl() {
    let form = document.getElementById('registration-form'); // <-- your formID
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
    (firstInvalidControl as HTMLElement).focus();
  }


  showError(params: any) {
    this.assessmentSvc.urlEncryption(params).subscribe((response: any) => {
      if (response) {
        this.router.navigate(['survey', 'error'], {
          queryParams: { v: response }
        });
        this.loaderSvc.hideLoader();
      }
    })
  }
}
