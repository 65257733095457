<app-header-menu [loginButton]=false></app-header-menu>


<div class="d-flex w-100 h-100 p-3 mx-auto flex-column" *ngIf="response">
    <!-- <header class="text-center">
        <img src="assets/images/favicon.ico" alt="" width="40" height="40">
        <img src="assets/images/logo.svg" height="40" class="ps-3" alt="phylaxis">
    </header> -->
    <div class="bg-img-start bg-dark" style="
background-image: url(../assets/svg/components/wave-pattern-light.svg);
">
        <div class="container content-space-t-3 content-space-t-lg-3 content-space-b-2">
            <div class="w-md-75 w-lg-100 text-center mx-md-auto">
                <h1 style="color:white">“Health is not valued until sickness comes.”</h1>
                <p style="color:white"> – Thomas Fuller</p>
            </div>
        </div>
    </div>
    <div class="row align-items-center justify-content-center mt-9" *ngIf="data; else content">
        <div class="col-12 col-lg-4 col-md-6  bg-light pt-3">
            <!-- <div class="row align-items-center justify-content-center pt-3">
                <div class="col-3 text-center">
                    <img src="assets/images/shield-check.svg" class="w-50" alt="phylaxis">
                </div>
            </div> -->
            <div class="row align-items-center justify-content-center">
                <img src="assets/images/shield-check.svg" alt="phylaxis" class="col-2 d-none d-md-block "
                    style="width: 8%;">
                <img src="assets/images/shield-check.svg" alt="phylaxis" class="col-2 d-block d-md-none"
                    style="width: 15%;">
                <h3 class=" loader col-2 ps-0 pt-3 ">Verified</h3>

            </div>
            <hr>
            <!-- <h3>{{data.assessment_name}}</h3>
                        
                        <h3>{{data.assessment_date| date:'fullDate'}}</h3> -->

            <table class="table">

                <tbody>
                    <tr>
                        <td class="text-black-50 h5">Assessment:</td>
                        <td class="fw-bold h5">{{data.nameDisplay}}</td>
                    </tr>
                    <tr>
                        <td class="text-black-50 h5">Reference:</td>
                        <td class="fw-bold h5" style="white-space: nowrap;">{{report.reference}}</td>
                    </tr>
                    <tr>
                        <td class="text-black-50 h5">Date & Time:</td>
                        <td class="fw-bold h5" style="white-space: nowrap;">
                            {{report.endTime|date:'dd MMM yyyy, HH:mm'}} Hrs</td>
                    </tr>
                    <tr>
                        <td class="text-black-50 h5">Location:</td>
                        <td class="fw-bold h5"><img [src]="data.flagUrl" height="30" alt="flag"
                                class="pe-2">
                            {{data.countryName}}</td>
                    </tr>



                </tbody>
            </table>




        </div>
    </div>

    <ng-template #content>
        <div class="row align-items-center justify-content-center mt-9">
            <!--        
        <div class="bg-light col-6 col-lg-6 col-md-6 mt-4 mb-5 text-center">
            
            <div class="row align-items-center justify-content-center mb-5">
                <div class="col-3 mt-5 text-center">
                    <img src="assets/images/error-circle.svg" class="w-50" alt="phylaxis">
                </div>
                <div class="col-sm-12 col-md-12 mt-5" >
                    <h4 class="mb-5 text-danger">Not verified</h4>
                    
                </div>
            </div>
        </div> -->
            <div class="col-10 col-lg-4 col-md-6  bg-light pb-5">
                <div class="row align-items-center justify-content-center mt-3 mb-3">
                    <div class="col-3 mt-5 text-center d-block d-lg-none">
                        <img src="assets/images/error-circle.svg" class="w-50" alt="phylaxis">
                    </div>

                    <div class="col-3 mt-5 text-center d-none d-lg-block">
                        <img src="assets/images/error-circle.svg" class="w-50" alt="phylaxis">
                    </div>
                </div>
                <div class="bg-light col-12 col-lg-12 col-md-12 mt-4 mb-5 text-center">
                    <div class="row align-items-center justify-content-center mb-5">
                        <div class="col-sm-12 col-md-12 mt-5">
                            <h2 class="mb-5 text-danger"> Not Verified</h2>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

</div>