import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuditTrial } from '../models/audit-trial';

@Injectable({
  providedIn: 'root'
})
export class AuditTrialService {
  constructor(private httClient: HttpClient) { }

  public sendAuditTrial(auditTrial: AuditTrial) {
   // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/audit/send`, auditTrial);
    return this.httClient.post<any>(`${environment.baseUrlAWSApi}/api/audit/send`, auditTrial);
  }

  sendInfoAudit(auditTrial: AuditTrial) {
    //   let request =
    //   {
    //     payload: payload,
    //     "event_source": "D2U_MW_Test1",
    //     "event": event,
    //     "sub_event": "Test_info",
    //     "user_type": "D2U",
    //     "logging_level": "info",
    //     "source_channel": "WEB",
    //     "remarks": remarks
    //   }
    //   this.auditTrial(request).subscribe(response => {
    //     console.log(response);
    //   })
  }
  
  sendError(auditTrial: AuditTrial) {
    auditTrial.logging_level = 'ERROR';
    return this.sendAuditTrial(auditTrial);
  }
}
