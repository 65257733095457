import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CognitoService } from 'src/app/services/cognito.service';
import { UserComponent } from '../../../auth/components/user/user.component';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  @Input() public loginButton: boolean = true;
  @Input() public myAccountButton: boolean = true;
  active = 1;
  isMenuCollapsed = true;
  user: any = null;
  isAuthenticated: boolean;
  constructor(public route: ActivatedRoute,
    private router: Router,
    private cognitoSvc: CognitoService,
    private modalSvc: NgbModal) {
    this.isAuthenticated = false;
  }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.cognitoSvc.authentication$.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });
    this.cognitoSvc.user$.subscribe(user => {
      this.user = user;
    });
  }

  public signOut(): void {
    this.cognitoSvc.signOut().then((success: any) => {
      console.log(success);
      // this.activeModal.dismiss();
      // this.router.navigate(['browse/landing']);
    });
  }

  openUser() {
    if (this.user) {
      this.router.navigate(['my-account']);
    } else {
      this.modalSvc.open(UserComponent, { backdrop: 'static', keyboard: false, size: 'xl' });
      //this.router.navigate(['core']);
    }
  }

  login() {
    // this.modalSvc.open(UserComponent, { backdrop: 'static', keyboard: false, size: 'xl' });
    this.router.navigate(['auth/login']);

  }
adminLogin(){
  // window.location.href = "http://test-zaf-cu-admin.eba-hgmfksjb.af-south-1.elasticbeanstalk.com/auth/login";
}
}
