import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private httClient: HttpClient) { }

  public getOrder(orderId: string) {
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/order/` + orderId);
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/order/` + orderId);
  }

  public getOrderStatus(orderId: string) {
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/order/` + orderId + '/status');
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/order/` + orderId + '/status');
  }

  public submitOrder(data: any) {
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/order/submit`, data);
    return this.httClient.post<any>(`${environment.baseUrlAWSApi}/api/order/submit`, data);
  }

  public rewardSubmit(data: any) {
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/reward/submit`, data);
    return this.httClient.post<any>(`${environment.baseUrlAWSApi}/reward/submit`, data);
  }

  public getReward(orderId: any) {
    //return this.httClient.get<any>(`${environment.baseUrlJavaApi}/reward/order/` + orderId);
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/reward/order/` + orderId);
  }

}
