import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  of,
  shareReplay,
} from 'rxjs';
import { Assessment } from 'src/app/models/assessment';
import { Country } from 'src/app/models/country';
import { GetLanguageRequest } from 'src/app/models/get-language-request';
import { GetLanguageResponse } from 'src/app/models/get-language-response';
import { Language } from 'src/app/models/language';
import { UserLocation } from 'src/app/models/location';

import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Amplify, API, Auth } from 'aws-amplify';
import { APP } from 'src/app/constants/app.constant';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  questions: any;
  reviewQuestions: any;
  // assessment: Assessment = new Assessment();
  // location: any;
  formValue: any = {};
  // assessmentType: any;
  // countryCode: any;

  private _country = new BehaviorSubject<Country>({});
  public readonly country$ = this._country.asObservable();

  private _language = new BehaviorSubject<Language>({});
  public readonly language$ = this._language.asObservable();

  private cache = new Map<string, Observable<GetLanguageResponse>>();

  constructor(
    private httClient: HttpClient,
    private router: Router,
    private storageSvc: StorageService,
    private cookieService: CookieService
  ) {
    Amplify.configure({
      // OPTIONAL - if your API requires authentication
      Auth: {
        mandatorySignIn: true,
        userPoolId: environment.cognito.userPoolId,
        region: environment.cognito.region,
        userPoolWebClientId: environment.cognito.userPoolWebClientId,
        identityPoolId: environment.cognito.identityPoolId,
      },
      API: {
        endpoints: [
          {
            name: 'AWS',
            endpoint: `${environment.baseUrlAWSApi}`,
            // endpoint: `${environment.baseUrlJavaApi}`
          },
        ],
      },
    });
  }

  public setLanguage(language: Language) {
    this._language.next(language);
  }

  public setCountry(country: Country) {
    this._country.next(country);
  }

  // public setAssessmentType(assessmentType: any) {
  //   this.assessmentType = assessmentType;
  // }

  // public setCountryCode(countryCode: any) {
  //   this.countryCode = countryCode;
  // }

  // public getAssessmentType() {
  //   return this.assessmentType;
  // }

  // public getCountryCode() {
  //   return this.countryCode;
  // }

  public setQuestions(questions: any) {
    this.storageSvc.setValue(APP.STORAGE.QUESTIONS, JSON.stringify(questions));
    this.questions = questions;
  }

  public setFormValue(data: any) {
    this.storageSvc.setValue(APP.STORAGE.FORM_VALUE, JSON.stringify(data));
    this.formValue = data;
  }

  public getFormValue() {
    if (this.formValue) {
      return this.formValue;
    } else {
      const formValue = JSON.parse(this.storageSvc.getValue(APP.STORAGE.FORM_VALUE));
      return formValue;
    }
  }

  public clearValues() {
    this.storageSvc.remove(APP.STORAGE.FORM_VALUE);
    this.storageSvc.remove(APP.STORAGE.QUESTIONS);
    this.storageSvc.remove(APP.STORAGE.COUNTRY_CODE);
    this.storageSvc.remove(APP.STORAGE.START_TIME);
    this.storageSvc.remove(APP.STORAGE.SERVICE_TYPE);
    this.storageSvc.remove(APP.STORAGE.LANGUAGE);
    this.storageSvc.remove(APP.STORAGE.QUESTIONS);
    this.storageSvc.remove(APP.STORAGE.ASSESSMENT_TYPE_CODE);
    this.storageSvc.remove(APP.STORAGE.ASSESSMENT_ID);
    this.storageSvc.remove(APP.STORAGE.LOCATION);
    this.storageSvc.remove(APP.STORAGE.COUNTRY_CODE);
    this.storageSvc.remove(APP.STORAGE.ASSESSMENT_CODE);
    this.storageSvc.remove(APP.STORAGE.COUNTRY);
  }

  public setReviewQuestions(reviewQuestions: any) {
    this.reviewQuestions = reviewQuestions;
  }

  public getQuestions() {
    if (this.questions) {
      return this.questions;
    } else {
      const questions = JSON.parse(this.storageSvc.getValue(APP.STORAGE.QUESTIONS));
      return questions;
    }

  }

  public getReviewQuestions() {
    return this.reviewQuestions;
  }

  public getUser() {
    const cookieExists: boolean = this.cookieService.check('user');
    const value: string = this.cookieService.get('user');
    //const allCookies: {} = this.cookieService.getAll();
    //console.log(allCookies);
    return value;
  }

  public setUser(user: any) {
    const cookieExp = user.end_user_id_duration * 7;
    this.cookieService.set('user', user.end_user_id, cookieExp);
    return user;
  }

  getAssessmentDetails(requestObj: any) {
    const apiName = 'AWS';
    const path = APP.API_URL.ASSESSMENT_DETAILS;
    const config = {
      headers: {},
      response: false,
      body: requestObj,
    };
    return API.post(apiName, path, config)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.resolve(error);
      });
  }

  public getAssessmentDetailsDirect(request) {
    return this.httClient.post<any>(`${environment.baseUrlAWSApi}/assessment/details`, request);
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/assessment/details`, data);
  }

  getLanguagesObservable(requestObj: any) {
    let request: any;
    if (this.cache.get(requestObj.code)) {
      request = this.cache.get(requestObj.code);
    } else {
      request = from(
        new Promise((resolve, reject) => {
          this.getAssessmentDetails(requestObj).then(
            (response) => {
              if (response) {
                resolve(response);
              } else {
                reject();
              }
            }
          );
        })
      )
        .pipe(
          map((response: any) => {
            //console.log('response headers', response)
            if (
              response &&
              response.response &&
              response.response.codes === APP.RESPONSE_STATUS.SUCCESS
            ) {
              this.setCountry(response.country);
              return response;
            } else {
              this.router.navigate(
                ['assessment', requestObj.code, 'error'],
                { state: response }
              );
              return response;
            }
          }),
          catchError((err: any) => {
            if (
              err &&
              err.error &&
              err.error.status === APP.RESPONSE_STATUS.ERROR
            ) {
              this.router.navigate(
                ['assessment', requestObj.code, 'error'],
                { state: err.error }
              );
            }
            console.log('response err', err);
            return err;
          })
        )
        .pipe(shareReplay(1));
      this.cache.set(requestObj.assessmentCategoryId, request);
    }
    return request ? request : of({});
  }

  //NOT USING
  public getLanguages(
    assessmentCategoryId: string
  ): Observable<GetLanguageResponse> {
    let request: any;
    if (this.cache.get(assessmentCategoryId)) {
      request = this.cache.get(assessmentCategoryId);
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),

        // withCredentials: true,
        // observe: 'response' as 'response'
      };
      const requestData = new GetLanguageRequest(
        assessmentCategoryId,
        APP.CHANNEL,
        this.getUser()
      );
      request = this.httClient
        .post<GetLanguageResponse>(
          `${environment.baseUrlAWSApi + APP.API_URL.ASSESSMENT_DETAILS}`,
          requestData,
          httpOptions
        )
        .pipe(
          // request = this.httClient.post<GetLanguageResponse>(`${environment.baseUrlJavaApi + APP.API_URL.ASSESSMENT_DETAILS}`, requestData, httpOptions).pipe(
          //request = this.httClient.post<GetLanguageResponse>(`${environment.baseUrlV2 + APP.API_URL.GET_LANGUAGE}`, requestData, httpOptions).pipe(
          map((response: any) => {
            //console.log('response headers', response)
            if (
              response &&
              response.response &&
              response.response.codes === APP.RESPONSE_STATUS.SUCCESS
            ) {
              this.setCountry(response.country);
              return response;
            } else {
              this.router.navigate(
                ['assessment', assessmentCategoryId, 'error'],
                { state: response }
              );
              return response;
            }
          }),
          catchError((err: any) => {
            if (
              err &&
              err.error &&
              err.error.status === APP.RESPONSE_STATUS.ERROR
            ) {
              this.router.navigate(
                ['assessment', assessmentCategoryId, 'error'],
                { state: err.error }
              );
            }
            console.log('response err', err);
            return err;
          })
        )
        .pipe(shareReplay(1));
      this.cache.set(assessmentCategoryId, request);
    }
    return request ? request : of({});
  }

  public getTerms(id: any, language: any) {
    return this.httClient.get<any>(
      `${environment.baseUrlAWSApi}/get-terms-and-conditions/${id}/${language}`
    );
  }

  public getPrivacyPolicy(id: any, language: any) {
    return this.httClient.get<any>(
      `${environment.baseUrlAWSApi}/get-privacy-policy/${id}/${language}`
    );
  }

  public addUserConsent(request: any) {
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/user/consent/status`, request);
    return this.httClient.post<any>(
      `${environment.baseUrlAWSApi}/api/user/consent/status`,
      request
    );
  }

  public getLanguage(
    requestObj: any,
    languageId: string
  ): Observable<Language> {
    const request: any = this.getLanguagesObservable(requestObj).pipe(
      map((response: GetLanguageResponse) => {
        const languageObj = {};
        if (response?.language?.length) {
          response.language.forEach((language: any) => {
            language.nameDisplay = language.nameDisplay;
            language.uiContent =
              language.uiContent && typeof language.uiContent === 'string'
                ? JSON.parse(language.uiContent)
                : language.uiContent;
            language.content =
              language.content && typeof language.content === 'string'
                ? JSON.parse(language.content)
                : language.content;
          });
          const language = response?.language?.find(
            (language: any) => language.languageCode === languageId
          );
          const languageObj = language ? language : response?.language[0];
          this.setLanguage(languageObj);
          return {
            language: languageObj,
            languages: response?.language,
            assessment: response?.assessment,
          };
        } else {
          return {
            language: languageObj,
            languages: response?.language,
            assessment: response?.assessment,
          };
        }
      })
    );
    return request ? request : of({});
  }

  public getQuestionsApi(
    country_id: number,
    assessment_id: string,
    language_id: string
  ) {
    let data = {
      country_id: country_id,
      assessment_id: assessment_id,
      language_id: language_id,
    };
    return this.httClient
      .post<any>(`${environment.baseUrlV2}/start-assessment`, data)
      .pipe(
        map((response: any) => {
          console.log('response headers', response);
          if (response && response.status === APP.RESPONSE_STATUS.SUCCESS) {
            return response;
          } else {
            this.router.navigate(['assessment', assessment_id, 'error'], {
              state: response,
            });
            return response;
          }
        }),
        catchError((err: any) => {
          if (
            err &&
            err.error &&
            err.error.status === APP.RESPONSE_STATUS.ERROR
          ) {
          }
          console.log('response err', err);
          return err;
        })
      );
  }

  public getQuestionsApiV2(
    country_id: string,
    assessment_id: string,
    language_id: string
  ) {
    let data = {
      country_id: country_id,
      assessment_id: assessment_id,
      language_id: language_id,
    };
    return this.httClient
      .post<any>(`${environment.baseUrlV2}/start-assessment`, data)
      .pipe(
        map((response: any) => {
          if (
            response &&
            response.status &&
            response.status === APP.RESPONSE_STATUS.SUCCESS
          ) {
            return response;
          } else {
            this.router.navigate(['assessment', assessment_id, 'error'], {
              state: response,
            });
            return response;
          }
        }),
        catchError((err: any) => {
          if (
            err &&
            err.error &&
            err.error.status === APP.RESPONSE_STATUS.ERROR
          ) {
          }
          console.log('response err', err);
          return err;
        })
      );
  }

  public getQuestionsApiV3(
    country_id: string,
    assessment_id: string,
    language_id: string
  ) {
    let data = {
      country_id: country_id,
      assessment_id: assessment_id,
      language_id: language_id,
      serviceType: "CAMPAIGN_AS_A_SERVICE",

    };
    return this.httClient
      .post<any>(`${environment.baseUrlV2}/start-assessment-v2`, data)
      .pipe(
        map((response: any) => {
          if (
            response &&
            response.response &&
            response.response.codes === APP.RESPONSE_STATUS.SUCCESS
          ) {
            return response;
          } else {
            this.router.navigate(['assessment', assessment_id, 'error'], {
              state: response,
            });
            return response;
          }
        }),
        catchError((err: any) => {
          if (
            err &&
            err.error &&
            err.error.status === APP.RESPONSE_STATUS.ERROR
          ) {
          }
          console.log('response err', err);
          return err;
        })
      );
  }

  public submitAssessment(data: any) {
    return this.httClient.post<any>(
      `${environment.baseUrlAWSApi}/assessment/submit`,
      data
    );
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/assessment/submit`, data);
  }

  public verifyAssessment(reference: string) {
    let codeScan = {
      reference: reference,
    };
    return this.httClient.post<any>(
      `${environment.baseUrlAWSApi}/report/verify`,
      codeScan
    );
  }

  public downloadReport(orderId: string) {
    return this.httClient.get<any>(
      `${environment.baseUrlAWSApi}/api/order/get-report/${orderId}`
    );
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/order/get-report/${orderId}`);
  }

  public downloadInvoice(orderId: string) {
    return this.httClient.get<any>(
      `${environment.baseUrlAWSApi}/invoice/order/${orderId}`
    );
  }

  public convertBase64ToPdf(data: any, name: any): void {
    const byteArray = this.base64ToArrayBuffer(data);
    const blobFile = new Blob([byteArray], { type: 'application/pdf' });
    const isIOS =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    if (navigator.userAgent.includes('Safari') && isIOS) {
      const url = URL.createObjectURL(blobFile);
      // const a: any = document.createElement('a');
      // a.href = url;
      // a.download = name + '.pdf';
      // a.target = '_blank';
      // a.click();
      window.open(url, '_self');
    } else {
      FileSaver.saveAs(blobFile, name + '.pdf');
    }
  }

  base64ToArrayBuffer(base64: string) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  urlEncryption(value: any) {
    let CryptoJS = require('crypto-js');
    let encryptedValue = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      environment.encryptionKey.key
    ).toString();
    return of(encodeURI(encryptedValue)); //object converted to string
  }

  urlDecryption(value: any) {
    let CryptoJS = require('crypto-js');
    let decryptedValue = CryptoJS.AES.decrypt(
      value,
      environment.encryptionKey.key
    );
    let originalData = decryptedValue.toString(CryptoJS.enc.Utf8);
    return of(JSON.parse(originalData));
  }

  // final File dwldsPath = new File(DOWNLOADS_FOLDER + fileName + ".pdf");
  // byte[] pdfAsBytes = Base64.decode(txt, 0);
  // FileOutputStream os;
  // os = new FileOutputStream(dwldsPath, false);
  // os.write(pdfAsBytes);
  // os.flush();
  // os.close();
  downloadVoucher(voucherId: any) {
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/reward/voucher/${voucherId}`);
    return this.httClient.get<any>(
      `${environment.baseUrlAWSApi}/reward/voucher/${voucherId}`
    );
  }

  downloadSurveyVoucher(voucherId: any) {
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/reward/voucher/${voucherId}`);
    return this.httClient.get<any>(
      `${environment.baseUrlAWSApi}/reward/survey-voucher/${voucherId}`
    );
  }

  public getLuckyDraw(reward: any) {
    return this.httClient.get<any>(
      `${environment.baseUrlAWSApi}/survey/luckydraw/?selectionId=${reward.userRewardSelectionDetailId}`
    );
  }

  updateRewardLuckyDrawWinner(rewardLuckyDrawWinnerId: any, data: any) {
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/user/create/earnback-template`, data);
    return this.httClient.put<any>(
      `${environment.baseUrlAWSApi}/survey/luckydraw/winner/` +
      rewardLuckyDrawWinnerId,
      data
    );
  }

  createRewardDelivery(rewardLuckyDrawWinnerId: any, data: any) {
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/user/create/earnback-template`, data);
    return this.httClient.post<any>(
      `${environment.baseUrlAWSApi}/survey/reward/delivery/` +
      rewardLuckyDrawWinnerId,
      data
    );
  }

  public resendDelivery(reward: any) {
    return this.httClient.get<any>(
      `${environment.baseUrlAWSApi}/survey/reward/delivery/resend/${reward.userRewardSelectionDetailId}`
    );
  }
  userCampaignValidate(data: any) {
    return this.httClient.post<any>(
      `${environment.baseUrlAWSApi}/user/campaign/validate`,
      data
    );
  }
}
