<main id="content" role="main">
  <!--HEADER-->
  <header id="header" class="navbar navbar-expand-lg navbar-end mb-3 navbar-sticky-top bg-white">
    <div class="row align-items-center justify-content-center mx-auto h-100 w-100 w-md-75">
      <div class="col-12 col-md-5 d-block text-start">
        <a class="navbar-brand" href="browse/landing" target="_blank">
          <img src="../../../../assets/images/Browse_Img/logos/main-wbg.png" alt="" style="height: auto; width: 13rem"
            height="auto" width="208" />
        </a>
      </div>
      <div class="col-12 col-md-7 d-none d-md-block text-end">
        <h5 class="text-primary text-right mb-0">
          enabling <span class="text-success">Personalized, </span>
          <span class="text-success">Prospective</span> and
          <span class="text-success">Preventative</span>
        </h5>
        <h5 class="text-primary text-right mb-0">
          healthcare as a <span class="text-success">Social Good</span>
        </h5>
      </div>
    </div>
  </header>
  <!--END HEADER-->

  <!--BANNER-->
  <div class="position-relative bg-dark mt-8">
    <!-- <a (click)="open(register)" role="button"> -->
      <img class="w-100 h-100 d-block" src="../../../../assets/images/yoga_day/top-banner-no-reg.jpg" alt="Yoga Day banner" />
    <!-- </a> -->
  </div>
  <div class="row w-md-100 w-sm-100 mx-auto" style="background-color: whitesmoke">
    <div class="col-md-6 px-4 py-4">
      <p class="mb-0 card-text">
        Yoga aims at attaining the purest form of self-awareness, free from all
        thoughts and sensations. Our ancient heritage of Yoga teaches us the art
        of living a life filled with blissful experiences and guides us in
        channelizing the flow of happiness in our lives.
      </p>
    </div>
    <div class="col-md-6 d-flex d-md-inline-block" style="background-color: #3ab549">
      <div class="px-4 py-4">
        <p class="text-white mb-0">
          In modern times, numerous scientific studies have demonstrated the
          efficacy of Yoga in addressing psychosomatic disorders. It is not only
          Yoga institutions but also other medical and scientific institutes
          that have worked towards scientifically validating the benefits of
          Yoga.
        </p>
      </div>
    </div>
  </div>
  <!--END BANNER-->

  <!--OPENING CARD-->
  <div class="container content-space-t-1 content-space-t-lg-2 content-space-b-1 content-space-b-lg-2">
    <!-- Heading -->
    <div class="card w-lg-100 text-center mx-lg-auto mx-auto">
      <p class="card-text p-4">
        Yoga has demonstrated immense benefits in the physical, mental,
        emotional and spiritual "well-being" of individuals who practice it
        daily. It is essential to utilize the occasion of the
        <b>"International Day of Yoga"</b> to reinstate these benefits in our
        minds.
      </p>
    </div>
  </div>
  <!--END OPENING CARD-->

  <!--EVENT DETAILS-->
  <div class="container content-space-1 bg-soft-success">
    <div class="row justify-content-lg-between align-items-lg-center ms-4 me-4">
      <div class="col-lg-5 p-md-4 p-sm-4">
        <!-- Blockquote -->
        <figure class="w-md-100 text-center mx-auto">
          <div class="mb-9 p-sm-0">
            <img class="mw-100 mh-100" src="../../../../assets/images/yoga_day/YOGAFORLIFE.png" />
          </div>
        </figure>
        <!-- End Blockquote -->
      </div>
      <!-- End Col -->
      <div class="col-lg-7 mb-3 mb-lg-0">
        <div class="mb-3">
          <h2 class="h1">
            Event:
            <span class="text-success">International Day of YOGA 2024</span>
          </h2>
        </div>
        <p class="card-text mb-0">
          <b>FREE</b> Entry. <b>FREE</b> t-shirt and water
          bottle for all participants.
        </p>
        <!-- <p class="card-text">   to all
                    participants.</p> -->
        <p class="mt-4 card-text mb-0"><b>Date:</b> Saturday, 22nd June 2024</p>
        <p class="card-text mb-0"><b>Time:</b> 09:30 to 14:00 hrs.</p>
        <p class="card-text">
          <b>Venue:</b><a
            href="https://www.google.com/maps/dir//Corlett+Dr,+Illovo,+Sandton,+2196/@-26.1325805,27.9745811,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1e950ce77b349b5f:0x329195e547119b82!2m2!1d28.0569826!2d-26.1326039?entry=ttu"
            target="_blank">
            Wanderers Cricket Stadium, Corlett Dr, Illovo, Johannesburg
          </a>
        </p>
        <p class="card-text">
          <b>Organised by:</b> Consulate General of India, Johannesburg
        </p>
        <div class="mt-4">
          <p class="small card-text mt-5">Registration for this event is closed.</p>
          <a class="btn btn-primary btn-transition px-5 disabled" style="pointer-events: none;"  (click)="open(register)">Register</a>
        </div>
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!--END EVENT DETAILS-->

  <!--YOGA ASSESSMENT-->
  <div class="content-space-t-2 content-space-b-1">
    <div class="mx-lg-auto p-2 p-md-5" style="
        background-image: -moz-linear-gradient(
          0deg,
          rgb(2, 170, 176) 0%,
          rgb(0, 205, 172) 100%
        );
        background-image: --webkit-linear-gradient(
          0deg,
          rgb(2, 170, 176) 0%,
          rgb(0, 205, 172) 100%
        );
        background-image: -ms-linear-gradient(
          0deg,
          rgb(2, 170, 176) 0%,
          rgb(0, 205, 172) 100%
        );
        background-image: linear-gradient(
          0deg,
          rgb(2, 170, 176) 0%,
          rgb(0, 205, 172) 100%
        );
      ">
      <!-- Card -->

      <h1 class="text-center text-white mb-0 mt-4 mt-md-0">
        YOGA Awareness Assessment
      </h1>
      <h4 class="text-center mb-3">(New Initiative)</h4>
      <div class="row text-center mx-auto">
        <img class="mx-auto mb-2" src="../../assets/images/yoga_day/yoga-and-ai-logo.jpg"
          style="width: 180px; height: auto;">
      </div>
      <div class="card mb-5 mx-0">
        <div class="card-body">
          <div class="row col-md-divider align-items-md-top">
            <div class="col-md-5 col-sm-12 h-100">
              <div class="row text-center h-100 justify-content-center text-center">
                <h4 class="card-title mb-2">
                  FREE ASSESSMENT,<span class="text-success">
                    EXCITING REWARDS!</span>
                </h4>
                <div class="col-12 justify-content-center my-auto">
                  <img class="h-auto w-100 w-lg-50 w-md-75 w-xl-35"
                    src="../../../../assets/images/yoga_day/GIFTBOX.png" />
                </div>
                <div class="col-12 col-xl-10">
                  <div class="row justify-content-center text-center">
                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/india-bazaar.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/Maya-Yoga.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3 py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/woolworths.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3 py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/Dis-Chem.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3 py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/sportsman-warehouse.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/heartfulness.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/virgin-active.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/brahma-kumari.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/earth-veda.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <!-- <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/ignite-fitness.jpg"
                        alt="Logo" />
                    </div> -->
                    <!-- End Col -->

                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/greenheart.jpg"
                        alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/maruti-media.jpg"
                        alt="Logo" />
                    </div>
                    <div class="col-4 col-lg-3  py-2">
                      <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/mohanji.jpg" alt="Logo" />
                    </div>
                    <!-- End Col -->

                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </div>
            <!-- End Col -->
            <div class="col-md-7 col-sm-12 ps-sm-0 ps-md-5">
              <div class="row">
                <h4 class="card-title mb-5 pt-2 pt-md-0">
                  FIRST OF IT'S KIND,
                  <span class="text-success">FIRST TIME EVER!</span>
                </h4>
                <p class="card-text mb-3">
                  <b>Scientifically designed assessment to help you:</b>
                </p>
                <div class="col-sm-6">
                  <!-- List Checked -->
                  <ul class="list-checked list-checked-bg-success list-checked-sm mb-2 mb-md-0">
                    <li class="list-checked-item">
                      Evaluate and improve your knowledge about Yoga philosophy,
                      fundamentals, practices, and benefits.
                    </li>
                    <li class="list-checked-item mb-2 mb-md-0">
                      Understand how wellness can be achieved through Yoga.
                    </li>
                  </ul>
                  <!-- End List Checked -->
                </div>
                <!-- End Col -->

                <div class="col-sm-6 mb-2 mb-sm-0">
                  <!-- List Checked -->
                  <ul class="list-checked list-checked-bg-success list-checked-sm mb-0">
                    <li class="list-checked-item">Clarify myths and facts.</li>
                    <li class="list-checked-item">
                      Understand accurate knowledge about Yoga, which is aimed
                      at taking care of the individual, the "Being".
                    </li>
                  </ul>
                  <!-- End List Checked -->
                </div>
                <!-- End Col -->
              </div>
              <!-- End Row -->
              <p class="small card-text mt-5">
                Recommended for people of age 18 years and above.
              </p>
              <div class="mt-2 mx-auto">
                <button type="button" class="btn btn-primary btn-transition" (click)="startAssessment()">
                  Start Assessment
                </button>

                <p class="card-text mt-5 bg-soft-success px-3 py-2">Existing users, <a class="text-success fw-bold"
                    (click)="startAssessment()" role="button"><u>click here</u></a> to download assessment report and
                  reward vouchers.</p>


              </div>
            </div>
          </div>

          <!-- End Col -->
          <!-- End Row -->
        </div>
      </div>
      <!-- End Card -->
    </div>
  </div>
  <!--END YOGA ASSESSMENT-->

  <!--EVENT SPONSORS-->
  <div class="content-space-t-0 content-space-t-md-2 content-space-b-1 content-space-b-md-3">
    <!-- Heading -->
    <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-6">
      <h2>Event Partners & Sponsors</h2>
    </div>
    <!-- End Heading -->

    <div class="row justify-content-center text-center align-items-center overflow-hidden m-0">
      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://mohanji.org" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Mohanji.png" alt="Mohanji">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://sahaja-yoga.co/en/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Sahajayoga.jpg" alt="Sahajayoga">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.yogajhb.co.za" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Sivananda_Ashram.png" alt="Sivananda Ashram">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://wellbeing.artofliving.org.za" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/The_art_of_living.png" alt="The art of living">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://brahmakumaris.org.za" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Brahma_kumari.png" alt="Brahma Kumari">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3">
        <a href="http://isha.co/idy-jhb" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Isha_Foundation.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://heartfulness.org/en" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Heartfulness.jpg" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="http://www.greenheartlife.co.za" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/greenHeart.jpg" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.baps.org/home.aspx" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/baps.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://abglobalmining.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/a&b.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://alfecoholdings.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/alfeco.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.barberry.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/barberry.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://za.linkedin.com/in/india-business-forum-south-africa-232a15246" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/cii.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="http://theclassicindia.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/classic_india.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.crigroups.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/cri.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.discovery.co.za/portal/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/discovery.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.virginactive.co.za" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/virgin-active.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://greatafricahaircompany.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/gah.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.gicresa.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/gic.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.hetero.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/hetero.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.iccr.gov.in/home" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/iccr.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.indiabazaar.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/india_bazaar.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->
      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.earthveda.in/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/earthveda.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->
      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://ionexchangesafic.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/ion_exchange.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.jindalafrica.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/jindal.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://kasiwarehouse.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/kasi.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://kirloskar-engines-sa.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/kirloskar.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://mahindra.co.za/mahindra-rise/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/mahindra.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.mphatek.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/mphatek.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.nihilent.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/nihilent.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://satgurutravel.com/south-africa/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/satguru.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.onlinesbi.sbi/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/sbi.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.tatamotors.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/tata.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.techmahindra.com" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/tech_mahindra.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.planetfitness.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/planet fitness.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.multiply.co.za/engaged/home" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/momentum.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://tsikoafrica.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/tsiko.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://vedanta-zincinternational.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/vedanta.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.vfsglobal.com/en/individuals/index.html" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/vfs.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.zeeworld.zeeafrica.tv/shows" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/zee.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.zensar.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/zensar.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.decathlon.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/decathlon.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.godrej.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/godrej.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.buffalocoal.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Buffalo Coal.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://cemza.co/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Cemza.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://homeopathy.org.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/hsa.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="http://ikwezimining.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Ikwezi Mining.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.facebook.com/ikweziresources/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Ikwezi Resources.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://immigrationpioneer.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Immigration Pioneer.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://incaconcrete.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Inca.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.eximbankindia.in/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/India Exim Bank.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.motherson.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Motherson.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://ozaholdings.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Oza Holdings.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="http://strongbuild.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/StrongBuild.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://syntheticpolymers.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Synthetic Polymers.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://tegaindustries.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/tega.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.viatris.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Viatris.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.buffalocoal.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Zinoju Coal.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://ozaholdings.com/zarbon-coal.html" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Zarbon Coal.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <!-- <a href="https://ozaholdings.com/zarbon-coal.html" role="button" target="_blank"> -->
        <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
          src="../../assets/images/yoga_day/sponsors/Nada Brahma.png" alt="Logo">
        <!-- </a> -->
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <!-- <a href="https://ozaholdings.com/zarbon-coal.html" role="button" target="_blank"> -->
        <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
          src="../../assets/images/yoga_day/sponsors/JMA.png" alt="Logo">
        <!-- </a> -->
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.ngglobal.co.za/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/NG.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.bhaktimarga.org/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/BhaktiMarga.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <!-- <a href="https://www.bhaktimarga.org/" role="button" target="_blank"> -->
        <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
          src="../../assets/images/yoga_day/sponsors/mathaji.png" alt="Logo">
        <!-- </a> -->
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <!-- <a href="https://www.bhaktimarga.org/" role="button" target="_blank"> -->
        <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
          src="../../assets/images/yoga_day/sponsors/wel.png" alt="Logo">
        <!-- </a> -->
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.blueberrygroup.co/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/blueberrygroup.jpeg" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.nirankari.org/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/niranka.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://ignitefitness.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Ignite.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="http://www.marutimedia.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/maruti-media.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <a href="https://www.tcs.com/" role="button" target="_blank">
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/TCS.png" alt="Logo">
        </a>
      </div>
      <!-- End Col -->

      <div class="col-4 col-sm-3 col-md-2 col-xl-2 py-3 mx-auto">
        <!-- <a href="http://www.marutimedia.com/" role="button" target="_blank"> -->
          <img class="avatar avatar-lg avatar-4x3 avatar-centered w-md-7"
            src="../../assets/images/yoga_day/sponsors/Skylabs.png" alt="Logo">
        <!-- </a> -->
      </div>
      <!-- End Col -->

    </div>
    <!-- End Row -->
  </div>
  <!--END EVENT SPONSORS-->

  <!-- Gallery -->
  <div class="position-relative bg-img-start">
    <div class="container pb-10 position-relative zi-2">
      <div class="w-md-75 text-center mx-md-auto">
        <!-- Heading -->
        <div class="mb-5">
          <h1>Gallery: International Day of YOGA 2023</h1>
          <p class="fst-italic small">
            (Gauteng, Limpopo, Mpumalanga, North-West)
          </p>
        </div>
        <!-- End Heading -->
      </div>

      <ngb-carousel class="d-none d-md-block">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row equal-height-row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4 equal-height-col">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img1.jpeg" alt="Image Description" />
              </div>
              <div class="col-12 col-md-4 equal-height-col">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img2.jpeg" alt="Image Description" />
              </div>
              <div class="col-12 col-md-4 equal-height-col">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img3.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img4.jpeg" alt="Image Description" />
              </div>
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img5.jpeg" alt="Image Description" />
              </div>
              <div class="col-12 col-md-4 align-content-center justify-content-end">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img6.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img7.jpeg" alt="Image Description" />
              </div>
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img8.jpeg" alt="Image Description" />
              </div>
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img9.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img10.jpeg" alt="Image Description" />
              </div>
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img11.jpeg" alt="Image Description" />
              </div>
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img12.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>

      <ngb-carousel class="d-block d-md-none">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row equal-height-row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4 equal-height-col">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img1.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row equal-height-row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4 equal-height-col">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img2.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row equal-height-row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4 equal-height-col">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img3.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img4.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img5.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img6.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img7.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img8.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img9.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img10.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img11.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="row gx-md-2 gy-sm-2 justify-content-center rh-15">
              <div class="col-12 col-md-4">
                <img class="img-fluid mh-15" src="../assets/images/yoga_day/img12.jpeg" alt="Image Description" />
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <!-- Shape -->
    <div class="shape shape-bottom zi-1">
      <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 500H3000V0L0 500Z" fill="#fff" />
      </svg>
    </div>
    <!-- End Shape -->
  </div>
  <!-- End Gallery -->

  <!--FOOTER-->
  <div class="bg-dark">
    <div class="container">
      <div class="w-md-85 text-center mx-lg-auto py-3">
        <p class="text-white-50 small">
          &copy;
          <a target="blank" href="https://www.phithos.com/"><b class="text-white-50">Phithos Technologies</b></a>. All
          rights reserved.
        </p>
        <p class="text-white-50 small">
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </p>
      </div>
    </div>
  </div>
  <!--END FOOTER-->
</main>
<!--End CONTENT-->

<!--GET IN TOUCH MODAL-->
<ng-template #register let-modal class="modal-dialog modal-dialog-centered">
  <div #target></div>

  <div class="mb-5 mb-md-0" *ngIf="screen === 'register'">
    <div class="modal-header mb-0 pb-0">
      <button type="button" class="btn-close mb-0" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body mt-0 mb-0 pt-0 pb-3">
      <div class="row text-center justify-content-center mt-0">
        <img class="w-50 h-50 w-md-25 h-md-25 mb-3" src="../../../../assets/images/yoga_day/YOGAFORLIFE.png" />
      </div>
      <p class="small fst-italic mt-0">
        <span class="text-danger">*</span> Mandatory fields
      </p>
      <!-- Form -->
      <form [formGroup]="registerForm" id="registration-form">
        <!-- <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label class="form-label fw-bold" for="hireUsFormFirstNameEg3">Full name*</label>
                        <input class="form-control" type="text" formControlName="userName" placeholder="Name Surname" />
                        <p class="text-danger small fst-italic mb-0" *ngIf="
                registerForm.get('userName').hasError('required') &&
                (registerForm.get('userName').touched|| registerForm.submitted)
              ">
                            Required
                        </p>
                        <p class="text-danger small fst-italic mb-0" *ngIf="
                        (registerForm.get('userName').hasError('maxlength') || registerForm.get('userName').hasError('whitespace')) &&
                        (registerForm.get('userName').touched || registerForm.submitted)
                      ">
                            Invalid
                        </p>
                    </div>
                </div> -->
        <!-- End Row -->

        <div class="row">
          <div class="col-sm-12 mb-3">
            <label class="form-label fw-bold" for="hireUsFormWorkEmailEg3">Email<span
                class="text-danger">*</span></label>
            <input type="email" formControlName="userEmail" class="form-control" name="hireUsFormNameWorkEmail"
              id="hireUsFormWorkEmailEg3" placeholder="email@site.com" aria-label="email@site.com" />
            <p class="text-danger small fst-italic mb-0" *ngIf="
                registerForm.get('userEmail').hasError('required') &&
                (registerForm.get('userEmail').touched ||
                  registerForm.submitted)
              ">
              Required
            </p>
            <p class="text-danger small fst-italic mb-0" *ngIf="
                registerForm.get('userEmail').hasError('pattern') &&
                (registerForm.get('userEmail').touched ||
                  registerForm.submitted)
              ">
              Invalid (eg: email@site.com)
            </p>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->

        <div class="row">
          <div class="col-sm-12 mb-3">
            <label class="form-label fw-bold" for="hireUsFormPhoneEg3">Phone<span class="text-danger">*</span>
              <span class="card-text" style="
                  font-weight: normal;
                  font-style: italic;
                  font-size: small;
                ">(eg. +279876543211, 09876543210)</span></label>
            <input type="text" formControlName="userPhoneNumber" class="form-control" name="hireUsFormNamePhone"
              id="hireUsFormPhoneEg3" placeholder="" aria-label="+27xx xxx xxxx" />
            <p class="text-danger small fst-italic mb-0" *ngIf="
                registerForm.get('userPhoneNumber').hasError('required') &&
                (registerForm.get('userPhoneNumber').touched ||
                  registerForm.submitted)
              ">
              Required
            </p>
            <p class="text-danger small fst-italic mb-0" *ngIf="
                registerForm.get('userPhoneNumber').hasError('pattern') &&
                (registerForm.get('userPhoneNumber').touched ||
                  registerForm.submitted)
              ">
              Invalid
            </p>
          </div>
          <!-- End Col -->
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label class="form-label fw-bold" for="hireUsFormPhoneEg3">Please add the details of the members who will be
              attending,
              including yourself.</label>
          </div>
          <div class="card mb-3 mt-0 p-2-5" formArrayName="familyMember"
            *ngFor="let f of familyMember.controls; let i = index">
            <div class="row gx-3 justify-content-center align-items-center" [formGroupName]="i">
              <div class="row mb-3 align-items-center mt-0 mx-0 p-0">
                <div class="col-10 col-md-11">
                  <p class="mb-0 text-primary fw-bold ms-0">
                    Member {{ i + 1 }} <span *ngIf="i == 0">(yourself)</span>
                  </p>
                </div>
                <div class="col-2 col-md-1 mx-auto">
                  <button *ngIf="i > 0" type="button" class="btn mb-0" aria-label="Close" (click)="deleteMember(i)">
                    <i class="bi bi-dash-square-fill text-danger h4"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-4 mb-3">
                  <label class="form-label fw-bold" for="full_name_{{ i }}">Full Name<span
                      class="text-danger">*</span></label>
                  <input class="form-control" type="text" formControlName="memberName" id="full_name_{{ i }}"
                    placeholder="Name Surname" />
                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('memberName')
                        .hasError('required') &&
                      (familyMember.controls[i].get('memberName').touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      (familyMember.controls[i]
                        .get('memberName')
                        .hasError('maxlength') ||
                        familyMember.controls[i]
                          .get('memberName')
                          .hasError('whitespace')) &&
                      (familyMember.controls[i].get('memberName').touched ||
                        registerForm.submitted)
                    ">
                    Invalid
                  </p>
                </div>
                <!-- End Col -->

                <div class="col-sm-12 col-md-4 mb-3">
                  <label class="form-label w-100 fw-bold">Gender<span class="text-danger">*</span></label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_male_{{ i }}" value="Male"
                      formControlName="memberGender" />
                    <label class="form-check-label" for="inlineRadio_male_{{ i }}">Male</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_female_{{ i }}" value="Female"
                      formControlName="memberGender" />
                    <label class="form-check-label" for="inlineRadio_female_{{ i }}">Female</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_other_{{ i }}" value="Other"
                      formControlName="memberGender" />
                    <label class="form-check-label" for="inlineRadio_other_{{ i }}">Other</label>
                  </div>
                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('memberGender')
                        .hasError('required') &&
                      (familyMember.controls[i].get('memberGender').touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                </div>
                <div class="col-sm-12 col-md-4 mb-3">
                  <label class="form-label w-100 fw-bold">Shirt Size<span class="text-danger">*</span></label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_xsmall_{{ i }}" value="X Small"
                      formControlName="memberShirtSize" />
                    <label class="form-check-label" for="inlineRadio_xsmall_{{ i }}">X Small</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_small_{{ i }}" value="Small"
                      formControlName="memberShirtSize" />
                    <label class="form-check-label" for="inlineRadio_small_{{ i }}">Small</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_medium_{{ i }}" value="Medium"
                      formControlName="memberShirtSize" />
                    <label class="form-check-label" for="inlineRadio_medium_{{ i }}">Medium</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_large_{{ i }}" value="Large"
                      formControlName="memberShirtSize" />
                    <label class="form-check-label" for="inlineRadio_large_{{ i }}">Large</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_xlarge_{{ i }}" value="X Large"
                      formControlName="memberShirtSize" />
                    <label class="form-check-label" for="inlineRadio_xlarge_{{ i }}">X Large</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="inlineRadio_xxlarge_{{ i }}" value="XX Large"
                      formControlName="memberShirtSize" />
                    <label class="form-check-label" for="inlineRadio_xxlarge_{{ i }}">XX Large</label>
                  </div>
                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('memberShirtSize')
                        .hasError('required') &&
                      (familyMember.controls[i].get('memberShirtSize')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                </div>
                <div class="col-sm-12 col-md-4 mb-3">
                  <label class="form-label fw-bold" for="{{ 'memberId' + i }}">ID/Passport
                  </label>
                  <input type="text" formControlName="memberId" id="{{ 'memberId' + i }}" class="form-control"
                    name="hireUsFormNamePhone" />
                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('memberId')
                        .hasError('maxlength') ||
                      (familyMember.controls[i]
                        .get('memberId')
                        .hasError('minlength') &&
                        (familyMember.controls[i].get('memberId').touched ||
                          registerForm.submitted))
                    ">
                    Invalid
                  </p>
                </div>
                <!-- End Col -->
                <div class="col-sm-12 col-md-4 mb-3">
                  <label class="form-label w-100 fw-bold" for="isMemberVitalityMember">Vitality Member<span
                      class="text-danger">*</span></label>
                  <label class="mt-0 w-100" style="font-style: italic; font-size: x-small">
                    (100 Vitality points will be awarded for participation)
                  </label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="isMemberVitalityMember_yes_{{ i }}" value="Yes"
                      formControlName="isMemberVitalityMember" />
                    <label class="form-check-label" for="isMemberVitalityMember_yes_{{ i }}">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="isMemberVitalityMember_no_{{ i }}" value="No"
                      formControlName="isMemberVitalityMember" />
                    <label class="form-check-label" for="isMemberVitalityMember_no_{{ i }}">No</label>
                  </div>

                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('isMemberVitalityMember')
                        .hasError('required') &&
                      (familyMember.controls[i].get('isMemberVitalityMember')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                </div>
                <div class="col-sm-12 col-md-4 mb-3" *ngIf="
                    familyMember.controls[i].get('isMemberVitalityMember')
                      .value === 'Yes'
                  ">
                  <label class="form-label fw-bold" for="{{ 'memberVitalityId' + i }}">Vitality Number<span
                      class="text-danger">*</span>
                  </label>
                  <input type="text" formControlName="memberVitalityId" id="{{ 'memberVitalityId' + i }}"
                    class="form-control" name="{{ 'memberVitalityId' + i }}" />

                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      (familyMember.controls[i]
                        .get('memberVitalityId')
                        .hasError('pattern') ||
                        familyMember.controls[i]
                          .get('memberVitalityId')
                          .hasError('maxlength') ||
                        familyMember.controls[i]
                          .get('memberVitalityId')
                          .hasError('minlength')) &&
                      (familyMember.controls[i].get('memberVitalityId')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Invalid
                  </p>
                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('memberVitalityId')
                        .hasError('required') &&
                      (familyMember.controls[i].get('memberVitalityId')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                </div>
                <div class="col-sm-12 col-md-4 mb-3">
                  <label class="form-label w-100 fw-bold" for="isMemberVaMember">Virgin Active Member<span
                      class="text-danger">*</span></label>
                  <label class="mt-0 w-100" style="font-style: italic; font-size: x-small">
                    ( <a class="text-decoration-underline" target="blank"
                      href="https://www.virginactive.co.za/guest-gym-pass">Click here</a> to claim a free day pass.
                    Virgin Active Ts and Cs apply. )
                  </label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="isMemberVaMember_yes_{{ i }}" value="Yes"
                      formControlName="isMemberVaMember" />
                    <label class="form-check-label" for="isMemberVaMember_yes_{{ i }}">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="isMemberVaMember_no_{{ i }}" value="No"
                      formControlName="isMemberVaMember" />
                    <label class="form-check-label" for="isMemberVaMember_no_{{ i }}">No</label>
                  </div>

                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('isMemberVaMember')
                        .hasError('required') &&
                      (familyMember.controls[i].get('isMemberVaMember')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                </div>

                <div class="col-sm-12 col-md-4 mb-3">
                  <label class="form-label w-100 fw-bold mb-0" for="isMemberMultiplyMember">Momentum Multiply
                    Member<span class="text-danger">*</span></label>
                  <label class="mt-0 w-100" style="font-style: italic; font-size: x-small">
                    (Register for free yoga mat)
                  </label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="isMemberMultiplyMember_yes_{{ i }}" value="Yes"
                      formControlName="isMemberMultiplyMember" />
                    <label class="form-check-label" for="isMemberMultiplyMember_yes_{{ i }}">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="isMemberMultiplyMember_no_{{ i }}" value="No"
                      formControlName="isMemberMultiplyMember" />
                    <label class="form-check-label" for="isMemberMultiplyMember_no_{{ i }}">No</label>
                  </div>

                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('isMemberMultiplyMember')
                        .hasError('required') &&
                      (familyMember.controls[i].get('isMemberMultiplyMember')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                </div>
                <div class="col-sm-12 col-md-4 mb-3" *ngIf="
                    familyMember.controls[i].get('isMemberMultiplyMember')
                      .value === 'Yes'
                  ">
                  <label class="form-label fw-bold" for="{{ 'memberMultiplyId' + i }}">Momentum Multiply Number / ID
                    Number
                    <span class="text-danger">*</span>
                  </label>
                  <input type="text" formControlName="memberMultiplyId" id="{{ 'memberMultiplyId' + i }}"
                    class="form-control" name="{{ 'memberMultiplyId' + i }}" />

                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      (familyMember.controls[i]
                        .get('memberMultiplyId')
                        .hasError('pattern') ||
                        familyMember.controls[i]
                          .get('memberMultiplyId')
                          .hasError('maxlength') ||
                        familyMember.controls[i]
                          .get('memberMultiplyId')
                          .hasError('minlength')) &&
                      (familyMember.controls[i].get('memberMultiplyId')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Invalid
                  </p>
                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('memberMultiplyId')
                        .hasError('required') &&
                      (familyMember.controls[i].get('memberMultiplyId')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                </div>
                <div class="col-sm-12 col-md-4 mb-3">
                  <label class="form-label w-100 fw-bold" for="isMemberPfMember">Planet Fitness Member<span
                      class="text-danger">*</span></label>
                  <label class="mt-0 w-100" style="font-style: italic; font-size: x-small" *ngIf="familyMember.controls[i]
                        .get('isMemberPfMember').value ==='No'">
                    ( <a class="text-decoration-underline" target="blank"
                      href="https://promos.pf.itensityonline.com/627/EventYogaDay20241week?utm_source=website&utm_medium=social&utm_campaign=eventyogaday20241week&utm_content=campain_627&source=23">Click
                      here</a> to register for your one week guest access. Planet Fitness Ts and Cs apply. )
                  </label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="isMemberPfMember_yes_{{ i }}" value="Yes"
                      formControlName="isMemberPfMember" />
                    <label class="form-check-label" for="isMemberPfMember_yes_{{ i }}">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="isMemberPfMember_no_{{ i }}" value="No"
                      formControlName="isMemberPfMember" />
                    <label class="form-check-label" for="isMemberPfMember_no_{{ i }}">No</label>
                  </div>

                  <p class="text-danger small fst-italic mb-0" *ngIf="
                      familyMember.controls[i]
                        .get('isMemberPfMember')
                        .hasError('required') &&
                      (familyMember.controls[i].get('isMemberPfMember')
                        .touched ||
                        registerForm.submitted)
                    ">
                    Required
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12">
            <button class="btn btn-primary btn-sm" (click)="addMember()" [disabled]="familyMember.length == 5">
              Add Member
            </button>
          </div>
          <div class="col-12">
            <span class="card-text my-auto ps-1 fst-italic fw-bold" style="font-size: x-small">
              You can add additional members (family/ friends) who will be
              joining the event.
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- Form -->
            <div class="mb-3">
              <div class="btn-group-vertical">
                <label class="form-label"><span class="fw-bold">For Vitality Members</span>: I
                  hereby consent that
                  Consulate General
                  of India, Johannesburg will share the data with Discovery
                  Vitality for the purpose of awarding Vitality points.<span class="text-danger">*</span></label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="inlineRadio_yes" value="Yes"
                    formControlName="vitalityPointsDisclaimer" />
                  <label class="form-check-label" for="inlineRadio_yes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="inlineRadio_no" value="No"
                    formControlName="vitalityPointsDisclaimer" />
                  <label class="form-check-label" for="inlineRadio_no">No</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="inlineRadio_not" value="Not a Vitality member"
                    formControlName="vitalityPointsDisclaimer" />
                  <label class="form-check-label" for="inlineRadio_not">I am not a Vitality member</label>
                </div>
                <p class="text-danger small fst-italic mb-0" *ngIf="
                    registerForm
                      .get('vitalityPointsDisclaimer')
                      .hasError('required') &&
                    (registerForm.get('vitalityPointsDisclaimer').touched ||
                      registerForm.submitted)
                  ">
                  Required
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <!-- Form -->
            <div class="mb-3">
              <div class="btn-group-vertical">
                <label class="form-label"><span class="fw-bold">For Momentum Multiply
                    Members</span>: For the purpose of registering to receive a free yoga mat, I consent that the
                  Consulate General of India, Johannesburg can share my data with Momentum Multiply.<span
                    class="text-danger">*</span></label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="multiplyPointsDisclaimer_yes" value="Yes"
                    formControlName="multiplyPointsDisclaimer" />
                  <label class="form-check-label" for="multiplyPointsDisclaimer_yes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="multiplyPointsDisclaimer_no" value="No"
                    formControlName="multiplyPointsDisclaimer" />
                  <label class="form-check-label" for="multiplyPointsDisclaimer_no">No</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="multiplyPointsDisclaimer_not"
                    value="Not a Momentum Multiply member" formControlName="multiplyPointsDisclaimer" />
                  <label class="form-check-label" for="multiplyPointsDisclaimer_not">I am not a Momentum Multiply
                    member</label>
                </div>
                <p class="text-danger small fst-italic mb-0" *ngIf="
                    registerForm
                      .get('multiplyPointsDisclaimer')
                      .hasError('required') &&
                    (registerForm.get('multiplyPointsDisclaimer').touched ||
                      registerForm.submitted)
                  ">
                  Required
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Form -->
        <div class="mb-3">
          <label class="form-label fw-bold" for="comments_field">Comments, if any?</label>
          <textarea class="form-control" formControlName="comments" name="comments_field" id="comments_field"
            placeholder="Comment..." aria-label="Tell us about your ..." rows="2"></textarea>
        </div>
        <!-- End Form -->

        <p class="fst-italic small card-text">
          Disclaimer: The data will be held securely and will not be distributed
          to third parties. When the information is no longer required, it will
          be disposed of.
        </p>
        <div class="mb-0">
          <label class="form-label">
            By continuing to use this service you agree to our
            <a href="https://zaf.phylaxis.ai/browse/privacy-policy" target=”_blank”>Privacy
              Policy</a> and
            <a href="https://zaf.phylaxis.ai/browse/terms" target=”_blank”>Terms of
              use</a>.
          </label>
        </div>
        <!-- <p class="text-danger small fst-italic mb-0" *ngIf="        
        (registerForm.valid ||
          registerForm.submitted)
      ">
          Please fix all form errors.
        </p> -->
      </form>
    </div>
    <div class="modal-footer p-3 mb-3 mb-md-0">
      <button type="button" class="btn btn-primary m-auto btn-sm" (click)="next(target)">
        Next
      </button>
    </div>
  </div>
  <div class="mb-5 mb-md-0" *ngIf="screen === 'review'">
    <div class="modal-header mb-0 pb-0">
      <button type="button" class="btn-close mb-0" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body mt-0 mb-3 pt-0">
      <div class="row text-center justify-content-center mt-0">
        <img class="w-50 h-50 w-md-25 h-md-25 mb-3" src="../../../../assets/images/yoga_day/YOGAFORLIFE.png" />
        <h4>Please review your details.</h4>
      </div>
      <div class="row">
        <div class="col-12">
          <!-- <div class="form-check p-0">
                        <label class="form-label fw-bold">Full name</label>

                        <label class="form-check-label text-black-50 ms-3">
                            {{ registerForm?.value?.userName }}
                        </label>
                    </div> -->
          <div class="form-check p-0">
            <label class="form-label fw-bold">Email</label>

            <label class="form-check-label text-black-50 ms-3">
              {{ registerForm?.value?.userEmail }}
            </label>
          </div>
          <div class="form-check p-0">
            <label class="form-label fw-bold">Phone</label>

            <label class="form-check-label text-black-50 ms-3">
              {{ registerForm?.value?.userPhoneNumber }}
            </label>
          </div>
          <div class="card p-2 mb-3" *ngFor="let f of registerForm?.value?.familyMember; let i = index">
            <div class="row mb-0">
              <div class="col-12 mb-1">
                <p class="mb-0 text-primary fw-bold">Member {{ i + 1 }}</p>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Full Name</label>
                  <p class="form-check-label text-black-50 mb-1">
                    {{ f?.memberName }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Gender</label>
                  <p class="form-check-label text-black-50 mb-1">
                    {{ f?.memberGender }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Shirt Size</label>
                  <p class="form-check-label text-black-50 mb-1">
                    {{ f?.memberShirtSize }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">ID/Passport</label>
                  <p class="form-check-label text-black-50 mb-1" *ngIf="f?.memberId != null">
                    {{ f?.memberId }}
                  </p>
                  <p class="form-check-label text-black-50 mb-1" *ngIf="f?.memberId == null">
                    -
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Vitality Member</label>
                  <p class="form-check-label text-black-50 mb-1">
                    {{ f?.isMemberVitalityMember }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Vitality Number</label>
                  <p *ngIf="f?.memberVitalityId != null" class="form-check-label text-black-50 mb-1">
                    {{ f?.memberVitalityId }}
                  </p>
                  <p class="form-check-label text-black-50 mb-1" *ngIf="f?.memberVitalityId == null">
                    -
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Virgin Active Member</label>
                  <p class="form-check-label text-black-50 mb-1">
                    {{ f?.isMemberVaMember }}
                  </p>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Momentum Multiply Member</label>
                  <p class="form-check-label text-black-50 mb-1">
                    {{ f?.isMemberMultiplyMember }}
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Momentum Multiply Number / ID Number</label>
                  <p *ngIf="f?.memberMultiplyId != null" class="form-check-label text-black-50 mb-1">
                    {{ f?.memberMultiplyId }}
                  </p>
                  <p class="form-check-label text-black-50 mb-1" *ngIf="f?.memberMultiplyId == null">
                    -
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check p-0">
                  <label class="form-label fw-bold">Planet Fitness Member</label>
                  <p class="form-check-label text-black-50 mb-1">
                    {{ f?.isMemberPfMember }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-check p-0">
            <label class="form-label fw-bold">Vatility Disclaimer</label>
            <label class="form-check-label text-black-50 ms-3">
              {{ registerForm?.value?.vitalityPointsDisclaimer }}
            </label>
          </div>
          <div class="form-check p-0">
            <label class="form-label fw-bold">Momentum Multiply Disclaimer</label>
            <label class="form-check-label text-black-50 ms-3">
              {{ registerForm?.value?.multiplyPointsDisclaimer }}
            </label>
          </div>
          <div class="form-check p-0">
            <label class="form-label fw-bold">Comments</label>

            <p class="form-check-label text-black-50" *ngIf="registerForm?.value?.comments != null">
              {{ registerForm?.value?.comments }}
            </p>
            <p class="form-check-label text-black-50" *ngIf="registerForm?.value?.comments == null">
              -
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer p-3 mb-3 mb-md-0">
      <div class="row mx-auto justify-content-center">
        <div class="col-6">
          <button type="button" class="btn btn-primary btn-sm" (click)="back(target)">
            Edit
          </button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-primary btn-sm" (click)="submitForm(target)">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="screen === 'complete'">
    <div class="modal-header mb-0 pb-0">
      <button type="button" class="btn-close mb-0" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body mt-0 mb-3 pt-0">
      <div class="row text-center justify-content-center mt-0">
        <img class="w-50 h-50 w-md-25 h-md-25 mb-3" src="../../../../assets/images/yoga_day/YOGAFORLIFE.png" />
      </div>
      <div class="row mb-4 justify-content-center">
        <div class="col-3 col-md-2 mb-1">
          <app-tick></app-tick>
        </div>
        <div class="col-12">
          <h3 class="mt-2 text-center">Successfully registered!</h3>
          <h4 class="mt-2 mb-2 text-center">
            For any queries, please write to
            <a href="mailto:idyjoburg@gmail.com">idyjoburg@gmail.com</a>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="card mb-5 mx-0 px-sm-2 px-md-2 px-lg-5">
          <div class="card-body px-0 px-lg-5">
            <div class="row align-items-md-top">
              <!-- End Col -->
              <div class="col-12 ps-sm-0 ps-md-2">
                <div class="row">
                  <div class="row text-center mx-auto">
                    <img class="mx-auto mb-2" src="../../assets/images/yoga_day/yoga-and-ai-logo-transparent.png"
                      style="width: 100px; height: auto;">
                  </div>
                  <h3 class="mb-1 pt-2 pt-md-0 text-center">Yoga Awareness Assessment</h3>
                  <h4 class="mb-4 text-center">(New Initiative)</h4>
                  <h4 class="card-title mb-3 text-center">
                    FREE ASSESSMENT,
                    <span class="text-success">EXCITING REWARDS!</span>
                  </h4>
                  <div class="col-12 col-md-7 mx-auto text-center">
                    <div class="row">
                      <div class="col-12">
                        <img class="w-75 h-auto mx-auto" src="../../../../assets/images/yoga_day/GIFTBOX.png" />
                      </div>
                      <div class="col-12 col-md-4 d-block d-md-none mb-2">
                        <div class="row justify-content-left text-center text-md-start mt-sm-0">
                          <div class="col-6 col-md-6 py-2 py-md-1 p">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/india-bazaar.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/Maya-Yoga.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/woolworths.jpg"
                              alt="Logo" />
                          </div>

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/Dis-Chem.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3"
                              src="../assets/images/yoga_day/sportsman-warehouse.jpg" alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/heartfulness.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/virgin-active.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/brahma-kumari.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/earth-veda.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/greenheart.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/maruti-media.jpg"
                              alt="Logo" />
                          </div>

                          <div class="col-6 col-md-12 py-2 py-md-1">
                            <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/mohanji.jpg"
                              alt="Logo" />
                          </div>
                          <!-- End Col -->
                        </div>
                        <!-- End Row -->
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5 mt-sm-5 mt-md-auto my-md-auto">
                    <!-- List Checked -->
                    <ul class="list-checked list-checked-bg-success list-checked-sm mb-0 mb-md-5">
                      <li class="list-checked-item">
                        Get a personalized assessment report.
                      </li>
                      <li class="list-checked-item">
                        It takes approximately 5 minutes to complete.
                      </li>
                      <li class="list-checked-item">
                        Recommended for people of age 18 years and above.
                      </li>
                    </ul>
                    <!-- End List Checked -->
                  </div>
                  <div class="col-12  d-md-block d-none">
                    <div class="row text-center mt-sm-0">
                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/india-bazaar.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/Maya-Yoga.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/woolworths.jpg"
                          alt="Logo" />
                      </div>

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/Dis-Chem.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/sportsman-warehouse.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/heartfulness.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/virgin-active.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/brahma-kumari.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/earth-veda.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/greenheart.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->

                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/maruti-media.jpg"
                          alt="Logo" />
                      </div>
                      <div class="col-4 col-xl-3 py-2">
                        <img class="avatar avatar-lg avatar-4x3" src="../assets/images/yoga_day/mohanji.jpg"
                          alt="Logo" />
                      </div>
                      <!-- End Col -->
                    </div>
                    <!-- End Row -->
                  </div>
                  <!-- End Col -->
                </div>
                <div class="mt-4 mx-auto w-100 align-items-center justify-content-center text-center">
                  <!-- <a class="btn btn-primary btn-transition" href="browse/platform">Start Assessment</a> -->
                  <button type="button" class="btn btn-primary btn-transition mx-auto" (click)="startAssessment()">
                    Start Assessment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--END GET IN TOUCH MODAL-->
<app-loader></app-loader>