import { APP } from "../constants/app.constant";

export class AuditTrial {
    logging_level = 'ERROR';
    source_channel = APP.CHANNEL;
    user_type = APP.USER_TYPE;
    payload: string;
    event_source = APP.EVENT_SOURCE;
    event?: string;
    sub_event?: string;
    remarks?: string;
    constructor(payload: string, event: string, sub_event: string) {
        this.payload = payload;
        this.event = event;
        this.sub_event = sub_event;
    }
}
