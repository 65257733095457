import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { IdleService } from './services/idle.service';
import { TimerService } from './services/timer.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  userActivity: any;
  userInactive: Subject<any> = new Subject();

  constructor(
    private idleService: IdleService,
    private meta:Meta,
    private timerSvc: TimerService) {
    idleService.idle$.subscribe(s => {
      // console.log('im idle, zzz')
      timerSvc.showTimer();
    });
    idleService.wake$.subscribe(s => {
      timerSvc.hideTimer();
      //console.log('im awake!')
    });
  }

}
