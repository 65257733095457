import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP } from 'src/app/constants/app.constant';
import { LoaderService } from 'src/app/services/loader.service';

import { AssessmentService } from '../services/assessment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-assessment',
  templateUrl: './verify-assessment.component.html',
  encapsulation: ViewEncapsulation.None,

  styleUrls: ['./verify-assessment.component.scss']
})
export class VerifyAssessmentComponent implements OnInit {
  report:any;
  language: any;
  data:any;
  response:any;
  showPopup = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private assessmentSvc: AssessmentService   
  ) {  }
  displayStyle = "none";
  ngOnInit(): void {
    this.activatedRoute.queryParams
      .subscribe((params: any) => {
        this.verifyAssessment(params.r);
      });
  }


  verifyAssessment(code: string) {    
    this.assessmentSvc.verifyAssessment(encodeURIComponent(code)).subscribe(response => { 
      this.response = response;     
      if (response && response?.response?.codes === APP.RESPONSE_STATUS.SUCCESS) {
        this.data = response.assessment;
        this.report=response.report;
        this.data.flagUrl = environment.baseUrlS3 + '/img/flags/' + this.data.userCountryAlpha2 + '.svg';

      }
    })
  }


}
