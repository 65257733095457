
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../services/assessment.service';


@Component({
  selector: 'app-code-decode',
  templateUrl: './code-decode.component.html',
  styleUrls: ['./code-decode.component.scss']
})
export class CodeDecodeComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private assessmentSvc: AssessmentService,
    private router: Router) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      let urlParams = params;
      if (urlParams.url) {
        const redirectUrl = urlParams['url'];
        let { url, ...newObj } = urlParams;
        // console.log(newObj); 
        this.assessmentSvc.urlEncryption(newObj).subscribe((response: any) => {
          let v = response;
          this.router.navigate([redirectUrl], {
            queryParams: { v }
          });
        })
      }

    });


  }

  ngOnInit(): void {

  }








}