
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoService, IUser } from 'src/app/services/cognito.service';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl, EmailValidator } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { AuditTrialService } from 'src/app/services/audit-trial.service';
import { AuditTrial } from 'src/app/models/audit-trial';
import { Location } from "@angular/common";
import { AssessmentService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  @Input() public screen: any = 'LOGIN';
  @Input() public data: any;

  showPasswordValue: any = {};
  //screen = '';

  user: IUser;
  form: any;
  confirmForm: any;
  newPasswordForm: any;
  message = '';
  errorMessage = '';
  countryLists: any;
  mobile = { code: '+27', number: null };
  params;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private cognitoSvc: CognitoService,
    private loaderSvc: LoaderService,
    private activatedRoute: ActivatedRoute,
    private toastSvc: ToastService,
    private assessmentSvc: AssessmentService,
    private auditTrialSvc: AuditTrialService,
    private location: Location,
    private modalService: NgbModal,
    private userSvc: UserService) {

    if (this.activatedRoute.snapshot.paramMap && this.activatedRoute.snapshot.paramMap.get('screen')) {
      this.screen = this.activatedRoute.snapshot.paramMap.get('screen')?.toUpperCase();
    }
    if (this.router.getCurrentNavigation()?.extras.state) {
      const state: any = this.router.getCurrentNavigation()?.extras.state;
      this.data = state.data;
    }

    this.user = {} as IUser;
    this.form = this.formBuilder.group({
      // email: ['', Validators.required],
      email: ['', Validators.compose([
        this.conditionalValidatorBasedOnScreen(['REGISTER']),
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password: ['', [this.conditionalValidatorBasedOnScreen(['LOGIN', 'REGISTER']), Validators.minLength(6)]],
      confirmPassword: ['', [this.conditionalValidatorBasedOnScreen(['REGISTER'])]],
      consent: ['', [this.conditionalValidatorBasedOnScreen(['REGISTER'])]],
      phone_number: ['', Validators.compose([this.conditionalValidatorBasedOnScreen(['LOGIN', 'REGISTER']),
      ])],
      // Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
      name: ['', [this.conditionalValidatorBasedOnScreen(['REGISTER'])]],
    },
      {
        validator: this.confirmedValidator('password', 'confirmPassword')
      });

    this.confirmForm = this.formBuilder.group({
      code: ['', Validators.required],
      phone_number: ['']
    });

    this.newPasswordForm = this.formBuilder.group({
      phone_number: [''],
      code: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: this.confirmedValidator('password', 'confirmPassword')
      }
    );

  }
  ngOnInit() {
    this.loaderSvc.hideLoader();
    this.getCountryList();
    this.form['submitted'] = false;
    this.activatedRoute.queryParams.subscribe(response => {
      if (response['v']) {
        this.assessmentSvc.urlDecryption(response['v']).subscribe((params: any) => {
          if (params) {
            this.params = params;
          }
        });
      }
    });
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if ((this.screen == 'REGISTER' || this.screen == 'NEWPASSWORD') && control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  selectCode(code: any) {
    if (this.mobile && this.mobile.code) {
      this.mobile.code = code;
    }
  }

  conditionalValidatorBasedOnScreen(screens: string[] = []): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (screens.indexOf(this.screen) >= 0 && !(control.value && control.value !== '')) {
        return { required: true }
      } else {
        return null;
      }
    };
  }

  openScreen(screen: string) {
    this.form['submitted'] = false;
    this.errorMessage = '';
    this.screen = screen;
    this.form.reset();
  }

  getCountryList() {
    this.userSvc.getCountryList().subscribe(response => {
      this.countryLists = response.country;
    })
  }

  startAssessment() {
    this.activeModal.dismiss();
    this.loaderSvc.showLoader();
    this.userSvc.getAnonymousUser().subscribe(response => {
      console.log(response);
      if (response.response.codes === "R00") {

        const user: any = JSON.parse(atob(response.anonymousUser));
        this.cognitoSvc.signIn({ phone_number: user.phone_number, password: user.password })
          .then((response) => {
            console.log(response);
            this.form.reset();
            this.activeModal.dismiss();
            this.loaderSvc.hideLoader();
            this.assessmentSvc.urlEncryption(this.params).subscribe((response: any) => {
              if (response) {
                let v = response;
                this.router.navigate(['process', 'step'], {
                  queryParams: { v }
                });
                this.loaderSvc.hideLoader();
              }
            });

          }).catch((response) => {
            console.log(response);
            // this.errorMessage = response.message;
            this.toastSvc.showError(response.message);
            this.loaderSvc.hideLoader();

          });
      }
    });

  }

  public signUp(): void {
    this.form['submitted'] = true;

    if (this.form.valid) {
      this.loaderSvc.showLoader();
      const request: any = JSON.parse(JSON.stringify(this.form.value));
      request.phone_number = this.mobile.code + this.form.value.phone_number;
      this.cognitoSvc.signUp(request)
        .then((response) => {

          if (response.__type === 'UsernameExistsException') {
            this.toastSvc.showError(response.message);
          } else {
            console.log(response);
            if (response.codeDeliveryDetails) {
              this.toastSvc.showSuccess("Verification code send to " + response.codeDeliveryDetails.Destination);
            }

            this.loaderSvc.hideLoader();
            // this.modalService.open(content, { centered: true });
            this.screen = 'OTP';

          }
        }).catch((response) => {
          console.log(response);
          // this.errorMessage = response.message;
          this.toastSvc.showError(response.message);
          const auditTrial = new AuditTrial(JSON.stringify(response), 'REGISTRATION', 'D2U_USER_REGISTRATION');
          this.auditTrialSvc.sendError(auditTrial).subscribe();
          this.loaderSvc.hideLoader();
        });
    }
  }
  signupProceed() {
    this.modalService.dismissAll();
    this.screen = 'LOGIN';

  }

  public resendSignUp(): void {
    this.loaderSvc.showLoader();
    this.cognitoSvc.resendSignUp(this.form.value)
      .then((response) => {
        console.log(response);
        this.loaderSvc.hideLoader();
        this.screen = 'OTP';
      }).catch(() => {
        this.loaderSvc.hideLoader();
      });
  }

  public confirmSignUp(content: any): void {
    this.confirmForm['submitted'] = true;
    if (this.confirmForm.valid) {
      this.loaderSvc.showLoader();
      this.confirmForm.get('email')?.setValue(this.form.value.email);
      this.confirmForm.get('phone_number')?.setValue(this.form.value.phone_number);
      const request: any = this.confirmForm.value;
      request.phone_number = this.mobile.code + this.confirmForm.value.phone_number;
      this.cognitoSvc.confirmSignUp(request)
        .then((response) => {
          console.log(response);
          this.loaderSvc.hideLoader();
          this.modalService.open(content, { centered: true });
          // this.screen = 'LOGIN';
          // this.router.navigate(['/signIn']);


        }).catch(() => {
          this.loaderSvc.hideLoader();
        });
    }
  }

  public signIn(): void {
    this.form['submitted'] = true;
    if (this.form.valid) {
      this.loaderSvc.showLoader();
      const request: any = this.form.value;
      request.phone_number = this.mobile.code + this.form.value.phone_number;
      this.cognitoSvc.signIn(request)
        .then((response) => {
          //console.log(response);
          this.form.reset();
          this.activeModal.dismiss();
          this.loaderSvc.hideLoader();
          if (this.data && this.data.code) {
            this.router.navigate(['process', this.data.id, 'stepv2', this.data.code]);
          } else if (this.data && this.data.id) {
            this.router.navigate(['process', this.data.id, 'stepv2']);
          } else {
            this.location.back();
          }
        }).catch((response) => {
          //console.log(response);
          this.errorMessage = response.message;
          const auditTrial = new AuditTrial(JSON.stringify(response), 'LOGIN', 'D2U_USER_LOGIN');
          this.auditTrialSvc.sendError(auditTrial).subscribe();
          this.loaderSvc.hideLoader();
        });

    }

  }

  forgotPassword() {
    this.screen = 'FORGOTPASSWORD';
  }

  public forgotPasswordNext() {
    const request: any = this.form.value;
    request.phone_number = this.mobile.code + this.form.value.phone_number;
    this.form['submitted'] = true;
    if (this.form.valid) {
      this.loaderSvc.showLoader();
      this.cognitoSvc.forgotPassword(this.form.value)
        .then((response) => {
          //console.log(response);
          this.screen = 'NEWPASSWORD';
          this.loaderSvc.hideLoader();

        }).catch((response) => {
          //console.log(response);
          this.errorMessage = response.message;
          this.loaderSvc.hideLoader();
          const auditTrial = new AuditTrial(JSON.stringify(response), 'FORGOT_PASSWORD', 'D2U_USER_FORGOT_PASSWORD');
          this.auditTrialSvc.sendError(auditTrial).subscribe();
        })

    }

  }

  public forgotPasswordSubmit(content: any) {
    this.newPasswordForm['submitted'] = true;
    if (this.newPasswordForm.valid) {
      this.loaderSvc.showLoader();
      this.newPasswordForm.get('email')?.setValue(this.form.value.email);
      this.newPasswordForm.get('phone_number')?.setValue(this.form.value.phone_number);
      const request: any = this.newPasswordForm.value;
      request.phone_number = this.newPasswordForm.value.phone_number;
      this.cognitoSvc.forgotPasswordSubmit(request)
        .then((response) => {
          console.log(response);
          this.loaderSvc.hideLoader();
          this.modalService.open(content, { centered: true });

          // this.screen = 'LOGIN';
          // this.loaderSvc.hideLoader();
          // this.form.get('password')?.setValue(this.newPasswordForm.value.password);

        }).catch((response) => {
          console.log(response);
          this.errorMessage = response.message;
          this.loaderSvc.hideLoader();
        })
    }
  }
  continueForgotpassword() {
    this.modalService.dismissAll();
    this.screen = 'LOGIN';
    this.form.get('password')?.setValue(this.newPasswordForm.value.password);
  }
  cancelForgotPassword() {
    this.modalService.dismissAll();
    this.screen = 'FORGOTPASSWORD';
  }
  cancelRegister() {
    this.modalService.dismissAll();
    this.screen = 'REGISTER';
  }

  showPassword(field: string) {
    if (this.showPasswordValue && this.showPasswordValue[field]) {
      this.showPasswordValue[field] = false;
    } else {
      this.showPasswordValue[field] = true;
    }
  }

}
