<section class="animated_check_circle m-1">
  <svg viewBox="0 0 130.2 130.2">
    <circle
      class="path circle"
      fill="none"
      stroke="#12b58f"
      stroke-width="8"
      stroke-miterlimit="10"
      cx="65.1"
      cy="65.1"
      r="60"
    />
    <polyline
      class="path check"
      fill="none"
      stroke="#12b58f"
      stroke-width="8"
      stroke-linecap="round"
      stroke-miterlimit="10"
      points="100.2,40.2 51.5,88.8 29.8,67.5"
    />
  </svg>

  
</section>