import { CoreModule } from '../core/core.module';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';

import { OrderService } from '../services/order.service';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { UserComponent } from './components/user/user.component';
import { AuthComponent } from './auth.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AuthComponent,
    UserComponent

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    CoreModule,
    RouterModule,
    ClipboardModule,
    NgbDropdownModule,
  ],
  providers: [
    DatePipe,
    OrderService
  ],
})
export class AuthModule { }
