<!-- <div class="modal-header">
  <h4 class="modal-title">Hi there!</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div> -->
<!-- <div class="modal-body"> -->
<!-- <div class="row ">
    <div class="col-12 text-end">
      <button type="button" class="btn-close" title ="Close"aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
  </div> -->
<div class="container">
  <div class="card mt-9">
    <div class="row justify-content-center my-5">
      <div class="text-center mb-5 mb-md-7">
        <!-- <h1 class="h2">Phylaxis</h1> -->
        <h3 *ngIf="screen === 'OPTION'">How would you like to continue ?</h3>
        <p *ngIf="screen === 'OPTION'" class="mb-0 pt-2">
          Quality of service won't differentiate for both types of users
        </p>
      </div>
      <div class="col-12 col-md-10" *ngIf="screen === 'OPTION'">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-md-0 mt-3 mt-lg-0">
            <!-- Card -->
            <div class="card bg-primary h-100 zi-1">
              <div class="card-header text-center">
                <h3 class="card-title text-white">Registered User</h3>
                <p class="card-text text-white">
                  <span class="fw-bold">mandatory</span> to declare name and
                  mobile number
                </p>
              </div>

              <div class="card-body d-flex justify-content-center py-0">
                <div class="row">
                  <div class="col-sm">
                    <!-- List Checked -->
                    <ul class="list-checked">
                      <li class="text-white">
                        <span class="bi bi-check-circle-fill text-success me-3"></span>Assessment report
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li class="text-white">
                        <span class="bi bi-check-circle-fill text-success me-3"></span>All available rewards
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li class="text-white">
                        <span class="bi bi-check-circle-fill text-success me-3"></span>Earnback reward option (if
                        available)
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li class="text-white">
                        <span class="bi bi-check-circle-fill text-success me-3"></span>Access to past assessments
                      </li>
                    </ul>

                    <ul class="list-checked">
                      <li class="text-white">
                        <span class="bi bi-check-circle-fill text-success me-3"></span>Access to past reports
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li class="text-white">
                        <span class="bi bi-check-circle-fill text-success me-3"></span>Information regarding
                        ongoing/forthcoming promotions
                      </li>
                    </ul>
                    <!-- End List Checked -->
                  </div>
                </div>
              </div>

              <div class="card-footer text-center">
                <div class="d-grid mb-2">
                  <!--                
                <p class="card-text text-white-70 small">You want to  Register ?</p> -->
                  <button type="button" class="btn btn-light" (click)="openScreen('REGISTER')">
                    Continue
                  </button>
                  <!-- <p class="card-text text-white-70 small">No card required</p> -->
                </div>
              </div>
              <!-- <input type="text" formControlName="phone_number" class="border-right-0 form-control "
                placeholder="Enter Mobile Number.." aria-label="Username" aria-describedby="basic-addon1"> -->
            </div>
            <!-- End Card -->
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-md-0">
            <!-- Card -->
            <div class="card h-100 zi-1">
              <div class="card-header text-center">
                <h3 class="card-title">Anonymous User</h3>
                <p class="card-text">
                  <span class="fw-bold">no need </span>to declare name and
                  mobile number
                </p>
              </div>

              <div class="card-body d-flex justify-content-center py-0">
                <div class="row">
                  <div class="col-sm">
                    <!-- List Checked -->
                    <ul class="list-checked">
                      <li>
                        <span class="bi bi-check-circle-fill text-success me-3"></span>Assessment report
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li>
                        <span class="bi bi-check-circle-fill text-success me-3"></span>Selected rewards only
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li>
                        <span class="bi bi-x-circle-fill text-danger me-3"></span>Earnback reward option
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li>
                        <span class="bi bi-x-circle-fill text-danger me-3"></span>Access to past assessments
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li>
                        <span class="bi bi-x-circle-fill text-danger me-3"></span>Access to past reports
                      </li>
                    </ul>
                    <ul class="list-checked">
                      <li>
                        <span class="bi bi-x-circle-fill text-danger me-3"></span>Information regarding
                        ongoing/forthcoming promotions
                      </li>
                    </ul>

                    <!-- End List Checked -->
                  </div>
                </div>
              </div>

              <div class="card-footer text-center">
                <div class="d-grid mb-2">
                  <!-- <p class="card-text text-muted small">You want to continue as Anonymous  ?</p> -->
                  <button type="button" class="btn btn-soft-primary" (click)="startAssessment()">
                    Continue
                  </button>
                </div>
                <!-- <p class="card-text text-muted small">No card required</p> -->
              </div>
            </div>
            <!-- End Card -->
          </div>
          <!-- End Col -->

          <!-- End Col -->
        </div>
      </div>
      <div class="col-sm-10 col-md-6 co-lg-6" *ngIf="screen === 'LOGIN'">
        <form [formGroup]="form" class="px-5">
          <!-- Form -->
          <div *ngIf="errorMessage" class="alert alert-soft-warning" role="alert">
            {{ errorMessage }}
          </div>
          <div class="form-group mb-4">
            <label class="form-label" for="email">Your Mobile Number</label>
            <div class="input-group">
              <div class="btn-group">
                <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                  <button type="button" class="btn btn-white dropdown-toggle-split" ngbDropdownToggle>
                    {{ mobile.code }}
                  </button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button *ngFor="let country of countryLists; index as j" ngbDropdownItem
                      (click)="selectCode(country.country_calling_code)">
                      <img [src]="country.flag_url" [title]="country.name" height="30" width="50" class="mr-2"
                        alt="flag" />{{ country.country_calling_code }}
                    </button>
                  </div>
                </div>
              </div>
              <input type="text" formControlName="phone_number" class="border-right-0 form-control"
                placeholder="Enter Mobile Number.." aria-label="Username" aria-describedby="basic-addon1" />
            </div>
            <div id="phone_number-error" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('phone_number')?.errors?.['required']">
              Please enter a value
            </div>
            <div id="phoneNumber-pattern" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('phone_number')?.errors?.['pattern']">
              Please enter a valid phone number
            </div>
          </div>
          <!-- End Form -->

          <!-- Form -->
          <div class="form-group mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <label class="form-label" for="signupModalFormLoginPassword">Password</label>

              <a class="form-label-link" (click)="forgotPassword()" href="javascript:;">Forgot Password?</a>
            </div>

            <!-- <div class="input-group input-group-merge" data-hs-validation-validate-class>
            <input [type]="user.showPassword ? 'text' : 'password'" id="password" name="password"
              formControlName="password" class="form-control form-control-lg" placeholder="8+ characters required">
            <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;"
              (click)="user.showPassword = !user.showPassword">
              <i id="changePassIcon" class="bi-eye"></i>
            </a>
          </div> -->
            <div class="input-group input-group-merge" data-hs-validation-validate-class>
              <input type="password" [type]="showPasswordValue?.password ? 'text' : 'password'" id="password"
                name="password" formControlName="password" class="form-control form-control-lg"
                placeholder="8+ characters required" />
              <a class="border-0 input-group-text" href="javascript:;" (click)="showPassword('password')">
                <i class="bi-eye"></i>
              </a>
            </div>
            <div id="password-error" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('password')?.errors?.['required']">
              Please enter a value
            </div>
            <!-- <p *ngIf="errorMessage" class="text-danger">{{errorMessage}}</p> -->
          </div>
          <!-- End Form -->

          <div class="d-grid mb-3">
            <button type="submit" (click)="signIn()" class="btn btn-primary btn-lg">
              Log in
            </button>
          </div>

          <div class="text-center">
            <p>
              Don't have an account yet?
              <a class="link" (click)="openScreen('REGISTER')" href="javascript:;">Sign up here</a>
            </p>
          </div>
          <!-- <div class="row">
          <div class="col mb-2">
            <label for="email" class="form-label">Email:</label>
            <input type="email" id="email" name="email" formControlName="email" class="form-control form-control-sm">
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <label for="password" class="form-label">Password:</label>
            <div class="input-group input-group-sm">
              <input [type]="user.showPassword ? 'text' : 'password'" id="password" name="password"
                formControlName="password" class="form-control form-control-sm">
              <button type="button" class="btn btn-outline-secondary" (click)="user.showPassword = !user.showPassword">
                <i class="bi"
                  [ngClass]="{'bi-eye-fill': !user.showPassword, 'bi-eye-slash-fill': user.showPassword}"></i>
              </button>
            </div>
          </div>
        </div> -->
          <div class="row" *ngIf="message">
            <div class="col mb-2 text-danger">
              <p class="text-danger">{{ message }}</p>
            </div>
          </div>
          <!-- <div class="row">
          <div class="col d-grid">
            <button type="button" (click)="signIn()" class="btn btn-sm btn-success" [disabled]="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
              Login
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <a class="text-start" (click)="openScreen('REGISTER')">Register?</a>
          </div>
        </div> -->
        </form>
      </div>
      <div class="col-sm-10 col-md-6 co-lg-6" *ngIf="screen === 'REGISTER'">
        <!-- Form -->
        <form [formGroup]="form" class="px-5">
          <!-- Form -->

          <div *ngIf="errorMessage" class="alert alert-soft-warning" role="alert">
            {{ errorMessage }}
          </div>
          <div class="form-group mb-3">
            <label class="form-label" for="phone_number">Your Phone Number</label>
            <div class="input-group">
              <div class="btn-group">
                <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                  <button type="button" class="btn btn-white dropdown-toggle-split" ngbDropdownToggle>
                    {{ mobile.code }}
                  </button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button *ngFor="let country of countryLists; index as j" ngbDropdownItem
                      (click)="selectCode(country.country_calling_code)">
                      <img [src]="country.flag_url" [title]="country.name" height="30" width="50" class="mr-2"
                        alt="flag" />{{ country.country_calling_code }}
                    </button>
                  </div>
                </div>
              </div>
              <input type="text" formControlName="phone_number" class="border-right-0 form-control"
                placeholder="Enter Mobile Number.." aria-label="Username" aria-describedby="basic-addon1" />
            </div>
            <div id="phoneNumber-pattern" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('phone_number')?.errors?.['required']">
              Please enter a value
            </div>
            <div id="phoneNumber-pattern" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('phone_number')?.errors?.['pattern']">
              Please enter a valid phone number
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="form-label" for="signupSimpleSignupEmail">Your email</label>
            <input type="email" class="form-control form-control-lg" name="email" id="signupSimpleSignupEmail"
              placeholder="email@site.com" aria-label="email@site.com" formControlName="email" />

            <div id="email" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('email')?.errors?.['required']">
              Please enter a value
            </div>
            <div id="email-pattern" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('email')?.errors?.['pattern']">
              Please enter a valid format
            </div>
          </div>
          <!-- End Form -->

          <!-- Form -->

          <div class="form-group mb-3">
            <label class="form-label" for="SignupPassword">Password</label>
            <div class="input-group input-group-merge">
              <input type="password" [type]="showPasswordValue?.password ? 'text' : 'password'"
                class="form-control form-control-lg" name="password" formControlName="password" id="SignupPassword"
                placeholder="6+ characters required" />
              <a class="border-0 input-group-text" href="javascript:;" (click)="showPassword('password')">
                <i class="bi-eye"></i>
              </a>
            </div>
            <label class="col pt-1" [ngClass]="
                form.controls['password'].hasError('required') ||
                form.controls['password'].hasError('minlength')
                  ? 'text-danger'
                  : 'text-success'
              ">
              <i class="bi" [ngClass]="form.controls['password'].hasError('required') ||
            form.controls['password'].hasError('minlength')
            ? 'bi bi-x-circle-fill' : 'bi bi-check-circle-fill'"></i>
              Must contain atleast 6 characters!
            </label>
            <div id="password-error" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('password')?.errors?.['required']">
              Please enter a value
            </div>
          </div>
          <!-- End Form -->

          <!-- Form -->
          <div class="form-group mb-3">
            <label class="form-label" for="SignupConfirmPassword">Confirm password</label>

            <div class="input-group input-group-merge">
              <input type="password" [type]="
                  showPasswordValue?.confirmPassword ? 'text' : 'password'
                " class="form-control form-control-lg" name="confirmPassword" id="SignupConfirmPassword"
                placeholder="8+ characters required" formControlName="confirmPassword" />
              <a class="border-0 input-group-text" href="javascript:;" (click)="showPassword('confirmPassword')">
                <i class="bi-eye"></i>
              </a>
            </div>

            <div id="confirmpassword-error" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('confirmPassword')?.errors?.['required']">
              Please enter a value
            </div>
            <div id="match-error" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('confirmPassword')?.errors?.['confirmedValidator']">
              Passwords do not match
            </div>
            <!-- <p *ngIf="errorMessage" class="text-danger">{{errorMessage}}</p> -->
          </div>

          <div class="form-group mb-3">
            <label class="form-label" for="name">Your Name</label>
            <input type="text" class="form-control form-control-lg" name="name" id="name" placeholder="Name"
              aria-label="Name" formControlName="name" />

            <div id="name-error" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('name')?.errors?.['required']">
              Please enter a value
            </div>
          </div>
          <!-- End Form -->

          <!-- Check -->
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" id="signupHeroFormPrivacyCheck" formControlName="consent"
              name="signupFormPrivacyCheck" />
            <label class="form-check-label small" for="signupHeroFormPrivacyCheck">
              By submitting this form I have read and acknowledged the
              <a href="./page-privacy.html">Privacy Policy</a></label>

            <div id="consent" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('consent')?.errors?.['required']">
              Please accept our Privacy Policy.
            </div>
          </div>
          <!-- End Check -->

          <div class="d-grid mb-3">
            <button type="submit" class="btn btn-primary btn-lg" (click)="signUp()">
              Sign up
            </button>
          </div>
         

          <div class="text-center">
            <p>
              Already have an account?
              <a class="link" href="javascript:;" (click)="openScreen('LOGIN')">Log in here</a>
            </p>
          </div>
        </form>
        <!-- End Form -->
      </div>
      <!-- otpscreen -->
      <div class="col-6" *ngIf="screen === 'OTP'">
        <form [formGroup]="confirmForm">
          <div class="row">
            <div class="form-group col mb-2">
              <label class="form-label" for="code">Code:</label>
              <input type="text" id="code" class="form-control form-control-lg" name="code" formControlName="code"
                class="form-control form-control-sm" />
              <div id="code" class="invalid-feedback"
                [class.d-block]="confirmForm.submitted && confirmForm.get('code')?.errors?.['required']">
                Please enter a value
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col d-grid">
              <button type="button" (click)="confirmSignUp(content)" class="btn btn-primary btn-lg">
                Confirm
              </button>
            </div>
          </div>
           <!-- modal content -->
           <ng-template #content let-modal>
            <!-- <div class="modal-header border-top-0">
              <div class="row">
                <button type="button" class="btn-close" title="Close" aria-label="Close" (click)="close()"></button>
              </div>
              </div> -->
            <div class="modal-body ">
              <h2 class="mt-5 text-center">Registration completed successfully</h2>
              <div class="row text-center m-5">
                <div class=" col-6">
                  <button type="button" class="btn btn-primary  mx-auto" (click)="signupProceed()">Continue</button>
                </div>
                <div class=" col-6">
                  <button type="button" rel="tooltip" title="Close" class="btn btn-danger mx-auto" (click)="cancelRegister()">
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- modal content -->
        </form>
      </div>
      <!-- otpscreen -->
      <!-- forgot password -->
      <div class="col-6" *ngIf="screen === 'FORGOTPASSWORD'">
        <div *ngIf="errorMessage" class="alert alert-soft-warning" role="alert">
          {{ errorMessage }}
        </div>
        <form [formGroup]="form">
          <div class="form-group mb-3">
            <label class="form-label" for="phone_number">Your Phone Number</label>
            <div class="input-group">
              <div class="btn-group">
                <button type="button" class="btn btn-white border-end-0">
                  {{ mobile.code }}
                </button>
                <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                  <button type="button" class="btn btn-white dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button *ngFor="let country of countryLists; index as j" ngbDropdownItem
                      (click)="selectCode(country.country_calling_code)">
                      <img [src]="country.flag_url" [title]="country.name" height="30" width="50" class="mr-2"
                        alt="flag" />{{ country.country_calling_code }}
                    </button>
                  </div>
                </div>
              </div>
              <input type="text" formControlName="phone_number" class="border-right-0 form-control"
                placeholder="Enter Mobile Number.." aria-label="Username" aria-describedby="basic-addon1" />
            </div>
            <div id="phoneNumber-pattern" class="invalid-feedback"
              [class.d-block]="form.submitted && form.get('phone_number')?.errors?.['required']">
              Please enter a value
            </div>
          </div>

          <div class="row mb-6">
            <div class="col d-grid">
              <button type="button" (click)="forgotPasswordNext()" class="btn btn-primary btn-lg">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- forgot password -->
      <!-- new password -->
      <div class="col-6" *ngIf="screen === 'NEWPASSWORD'">
        <form [formGroup]="newPasswordForm">
          <div *ngIf="errorMessage" class="alert alert-soft-warning" role="alert">
            {{ errorMessage }}
          </div>
          <div class="form-group col mb-2">
            <label class="form-label" for="code">Code:</label>
            <input type="text" id="code" class="form-control form-control-lg" name="code" formControlName="code"
              class="form-control form-control-sm" />
            <div id="code" class="invalid-feedback"
              [class.d-block]="newPasswordForm.submitted && newPasswordForm.get('code')?.errors?.['required']">
              Please enter a value
            </div>
          </div>
          <div class="form-group col mb-2">
            <label class="form-label" for="password">Password:</label>
            <div class="input-group input-group-merge">
              <input type="password" [type]="showPasswordValue?.password ? 'text' : 'password'" id="password"
                class="form-control form-control-lg" name="password" formControlName="password"
                class="form-control form-control-sm" />
              <a class="input-group-append input-group-text" href="javascript:;" (click)="showPassword('password')">
                <i class="bi-eye"></i>
              </a>
            </div>
            <div id="password-error" class="invalid-feedback"
              [class.d-block]="newPasswordForm.submitted && newPasswordForm.get('password')?.errors?.['required']">
              Please enter a value
            </div>
          </div>
          <div class="form-group col mb-2">
            <label class="form-label" for="confirmPassword">Confirm Password:</label>
            <div class="input-group input-group-merge">
              <input type="password" [type]="
                  showPasswordValue?.confirmPassword ? 'text' : 'password'
                " id="confirmPassword" class="form-control form-control-lg" name="confirmPassword"
                formControlName="confirmPassword" class="form-control form-control-sm" />
              <a class="input-group-append input-group-text" href="javascript:;"
                (click)="showPassword('confirmPassword')">
                <i class="bi-eye"></i>
              </a>
            </div>
            <div id="confirmPassword-error" class="invalid-feedback"
              [class.d-block]="newPasswordForm.submitted && newPasswordForm.get('confirmPassword')?.errors?.['required']">
              Please enter a value
            </div>
            <div id="match-error" class="invalid-feedback"
              [class.d-block]="newPasswordForm.submitted && newPasswordForm.get('confirmPassword')?.errors?.['confirmedValidator']">
              Passwords do not match
            </div>
          </div>

          <div class="row">
            <div class="col d-grid">
              <button type="button" (click)="forgotPasswordSubmit(content)" class="btn btn-primary btn-lg">
                Submit
              </button>
            </div>
          </div>
          <!-- modal content -->
          <ng-template #content let-modal>
            <!-- <div class="modal-header border-top-0">
              <div class="row">
                <button type="button" class="btn-close" title="Close" aria-label="Close" (click)="close()"></button>
              </div>
            </div> -->
            <div class="modal-body ">
              <h2 class="mt-5 text-center">Password updated successfully</h2>
            </div>
            <div class="row text-center m-5">
              <div class=" col-6">
                <button type="button" class="btn btn-primary  mx-auto"
                  (click)="continueForgotpassword()">Continue</button>
              </div>
              <div class=" col-6">
                <button type="button" rel="tooltip" title="Close" class="btn btn-danger mx-auto" (click)="cancelForgotPassword()">
                  CANCEL
                </button>
              </div>
            </div>
          </ng-template>
          <!-- modal content -->
        </form>
      </div>
      <!-- new password -->
    </div>
  </div>
</div>