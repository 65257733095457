export const APP = {
    CHANNEL: 'WEB',
    USER_TYPE: 'D2U',
    EVENT_SOURCE: 'D2U_FE_WEB',
    ANNONYMOUS: {
        EMAIL: 'guest@phithos.com'
    },
    API_URL: {
        GET_LANGUAGE: '/get-languages',
        ASSESSMENT_DETAILS: '/assessment/details',
        SURVEY_DETAILS: '/survey/details'

    },
    PAGINATION: {
        QUESTIONS_COUNT_ARRAY: [3]
    },
    RESPONSE_STATUS: {
        SUCCESS: "R00",
        ERROR_RO1: "R01",
        ERROR: 0
    },
    STORAGE: {
        LANGUAGE: 'LANGUAGE_ID',
        ASSESSMENT_ID: 'ASSESSMENT_ID',
        ASSESSMENT_CODE: 'ASSESSMENT_CODE',
        ASSESSMENT_TYPE_CODE: 'ASSESSMENT_TYPE_CODE',
        SERVICE_TYPE: 'SERVICE_TYPE',
        COUNTRY_CODE: 'COUNTRY_CODE',
        START_TIME: 'START_TIME',
        LOCATION: 'LOCATION',
        QUESTIONS: 'QUESTIONS',
        FORM_VALUE: 'FORM_VALUE',
        COUNTRY: 'COUNTRY',
        PATH_VALUE:'PATH_VALUE',
        ASSESSMENT_CATEGORY_CODE:'ASSESSMENT_CATEGORY_CODE'
    },
    NOTIFICATIONS: {
        SUCCESS: {
            type: 'success',
            timer: 2000,
            placement: {
                from: 'top',
                align: 'right'
            },
            template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
        },
        ERROR: {
            type: 'danger',
            timer: 2000,
            placement: {
                from: 'top',
                align: 'right'
            },
            template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
        }
    },
    ASSESSMENT: {
        SA_BREAST_CANCER: {
            images: ['assets/images/breast_cancer/breast-cancer_1.jpg', 'assets/images/breast_cancer/breast-cancer_2.jpg', 'assets/images/breast_cancer/breast-cancer_3.jpg', 'assets/images/breast_cancer/breast-cancer_4.jpg']
        }
    }

};