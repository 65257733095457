import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-tick',
  templateUrl: './tick.component.html',
  styleUrls: ['./tick.component.scss'],
})
export class TickComponent implements OnInit {
 




  constructor(
    
  ) {
    
  }

  ngOnInit(): void { }

  

  

  

}
