export class GetLanguageRequest {
    assessmentCategoryId?: string;
    end_user_id?: string;
    code?: string;

    constructor(assessmentCategoryId: string, end_user_id: any, code?: string) {
        this.assessmentCategoryId = assessmentCategoryId;
        // if (end_user_id) {
        //     this.end_user_id = end_user_id;
        // } else {
            this.end_user_id = "";
        //}
        if (code) {
            this.code = code;
        }
    }
} 