import { HttpClient } from '@angular/common/http';
import { Component, NgModule, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APP } from 'src/app/constants/app.constant';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-error-location',
  templateUrl: './error-location.component.html',
  encapsulation: ViewEncapsulation.None,

  styleUrls: ['./error-location.component.scss']
})
export class ErrorLocationComponent implements OnInit {
  language: any;
  data: any = {};
  showPopup = false;

  constructor(private router: Router) {
    this.data = this.router.getCurrentNavigation()?.extras.state;
  }
  displayStyle = "none";
  ngOnInit(): void {  }


  close() {
   
    window.location.href = environment.redirectPath;
  }
}
