import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-padel',
  templateUrl: './padel.component.html',
  styleUrls: ['./padel.component.scss']
})
export class PadelComponent implements OnInit {

  
  constructor() { }
  active = 1;
  ngOnInit(): void {
   
  }

  
}
