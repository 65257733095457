import { environment } from 'src/environments/environment';
import { PreloadAllModules, PreloadingStrategy, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ErrorLocationComponent } from './error-location/error-location.component';
import { VerifyAssessmentComponent } from './verify-assessment/verify-assessment.component';
import { CodeDecodeComponent } from './code-decode/code-decode.component';
import { YogadayComponent } from './yogaday/yogaday.component';
import { YogadayAdminComponent } from './yogaday-admin/yogaday-admin.component';
import { PadelComponent } from './padel/padel.component';
import { PadelGalleryComponent } from './padel-gallery/padel-gallery.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: environment.defaultUrl
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey/survey.module').then(module => module.SurveyModule)
  },
  {
    path: 'process',
    loadChildren: () => import('./process/process.module').then(module => module.ProcessModule)
  },
  {
    path: 'browse',
    loadChildren: () => import('./browse/browse.module').then(module => module.BrowseModule)
  },
  {
    path: 'assessment',
    loadChildren: () => import('./assessment/assessment.module').then(module => module.AssessmentModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then(module => module.OrderModule)
  },
  {
    path: 'error-location',
    component: ErrorLocationComponent
  },
  {
    path: 'v',
    component: VerifyAssessmentComponent
  },
  {
    path: 'e',
    component: CodeDecodeComponent    
  },
  {
    path: 'yogaforlife',
    component: YogadayComponent
  },
  {
    path: '360padel',
    component: PadelComponent
  },
  // {
  //   path: '360padel-gallery',
  //   component: PadelGalleryComponent
  // },
  {
    path: 'yogaday-admin',
    loadComponent: () => import('./yogaday-admin/yogaday-admin.component').then(mod => mod.YogadayAdminComponent)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
