import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { APP } from '../constants/app.constant';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(private storageSvc: StorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.storageSvc.getValue('JWT')) {
      console.log(request)
      if (request.url.includes(environment.baseUrlV2) && !request.url.includes('app-config') && !request.url.includes('rebrandly')) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${this.storageSvc.getValue('JWT')}`,
            'source-channel': APP.CHANNEL
            //  'source-ip':'197.184.179.240'
          }
        });
        return next.handle(request);

      } else if (!request.url.includes('app-config') && !request.url.includes('rebrandly')) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${this.storageSvc.getValue('JWT')}`,
            'source-channel': APP.CHANNEL
            //'source-ip':'197.184.179.240'
          }
        });
        return next.handle(request);

      }

    } else {
      request = request.clone({
        setHeaders: {
          'source-channel': APP.CHANNEL
          // 'source-ip':'197.184.179.240'
        }
      });
      return next.handle(request);

    }

    return next.handle(request);

  }

}
