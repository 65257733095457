import { ToastComponent } from './components/toast/toast.component';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '../helpers/translate.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { TimerComponent } from './components/timer/timer.component';
// import { UserComponent } from '../auth/components/user/user.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbDropdownModule, NgbNavModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { RouterModule } from '@angular/router';
import { FooterMenuComponent } from './components/footer-menu/footer-menu.component';
import { SafeHtmlPipe } from "./pipes/safehtml.pipe";
import { LuckyDrawRewardComponent } from './components/lucky-draw-reward/lucky-draw-reward.component';
// import { EarnbackComponent } from './components/earnback/earnback.component';
// import { ShareComponent } from './components/share/share.component';
import { UserRewardComponent } from './components/user-reward/user-reward.component';
import { AvailLuckyDrawComponent } from './components/avail-lucky-draw/avail-lucky-draw.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { UserSurveyComponent } from './components/user-survey/user-survey.component';
import { ModalPasscodeComponent } from './components/modal-passcode/modal-passcode.component';
import { TickComponent } from './components/tick/tick.component';
import { ModalOtpComponent } from './components/modal-otp/modal-otp.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { SmsPopupComponent } from './components/sms-popup/sms-popup.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbToastModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbNavModule,
    RouterModule,
    NgOtpInputModule,
    SelectDropDownModule
  ],
  declarations: [
    HeaderComponent,
    LoaderComponent,
    TimerComponent,
    TranslatePipe,
    LuckyDrawRewardComponent,
    FooterComponent,
    ToastComponent,
    ModalConfirmComponent,
    HeaderMenuComponent,
    FooterMenuComponent,
    SafeHtmlPipe,
    UserRewardComponent,
    // EarnbackComponent,
    // ShareComponent,
    AvailLuckyDrawComponent,
    UserSurveyComponent,
    ModalPasscodeComponent,
    TickComponent,
    ModalOtpComponent,
    SmsPopupComponent
  ],
  exports: [
    HeaderComponent,
    LoaderComponent,
    TimerComponent,
    TranslatePipe,
    FooterComponent,
    ToastComponent,
    HeaderMenuComponent,
    FooterMenuComponent,
    SafeHtmlPipe,
    UserRewardComponent,
    TickComponent
  ],
})
export class CoreModule {}
