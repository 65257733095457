import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from '../models/country';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _country = new BehaviorSubject<Country>({});
  public readonly country$ = this._country.asObservable();
  constructor(
    private httClient: HttpClient,
    private router: Router) { }

  setCountry(country: any) {
    this._country.next(country);
  }

  getCountry(): any {
    return this._country;
  }

  getConfig() {
    if (environment.featureToggles.useConfigApi) {      
      return this.httClient.get<any>(`${environment.baseUrlAWSApi}/app-config`).pipe(map((response: any) => {
        if (response && response.country) {
          this.setCountry(response.country)
        }
        return response;
      }));
    } else {
      this.setCountry(environment.country);
      return of({});
    }

  }


}
