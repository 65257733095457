import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, shareReplay, Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private _timer = new BehaviorSubject<boolean>(false);
  public readonly timer$ = this._timer.asObservable();

  constructor() { }

  public showTimer() {
    this._timer.next(true);
  }

  public hideTimer() {
    this._timer.next(false);
  }
}
