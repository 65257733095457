<div class="fixed-top h-100 position-fixed w-100" style="background-color: rgba(0, 0, 0, 0.8);z-index: 1100;" *ngIf="loading$ | async">
  <div class="row align-items-center justify-content-center h-100">
    <div class="col-md-8 text-center">
      <div class="spinner-border loader" role="status" style="width: 8em;height: 8em;">
        
        <!-- <span class="visually-hidden">{{'ui_content.display_text.loading_message' |
          translate}}</span> -->
      </div>
    </div>
  </div>
</div>