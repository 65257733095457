import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  // providers: [LoaderService]
})
export class LoaderComponent implements OnInit {
  loading$:any;
  constructor(private loaderSvc: LoaderService) {
    this.loading$ = this.loaderSvc.loading$;
  }

  ngOnInit(): void {
   
  }

}
