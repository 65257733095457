// import { AngularFireAnalyticsModule, CONFIG, DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbActiveModal, NgbCollapseModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderInterceptor } from './helpers/header.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
// import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { CoreModule } from './core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { APP_ID, APP_INITIALIZER, NgModule, PLATFORM_ID, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigService } from './services/config.service';
import { Observable, tap } from 'rxjs';
import { ToastService } from './services/toast.service';
import { AuthModule } from './auth/auth.module';
import { isPlatformBrowser } from '@angular/common';
// import { BrowseModule } from './browse/browse.module';
// import { AgmCoreModule } from '@agm/core';
import { YogadayComponent } from './yogaday/yogaday.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaV3Module } from 'ng-recaptcha';
import { PadelComponent } from './padel/padel.component';
import { PadelGalleryComponent } from './padel-gallery/padel-gallery.component';

export function initializeAppConfig(configSvc: ConfigService): () => Observable<any> {
  return () => configSvc.getConfig().pipe(    // load() function returns Observable
    tap((response) => {
      // add custom logic here
      // console.log(response);
    })
  )
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    CoreModule,
    RouterModule,
    NgbCollapseModule,
    AuthModule,
    NgbPaginationModule,
    // BrowseModule,
    RecaptchaV3Module,    
    RecaptchaFormsModule
  ],
  declarations: [
    AppComponent,
    YogadayComponent,
    PadelComponent,
    PadelGalleryComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,

    },
    CookieService,
    // { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    // { provide: DEBUG_MODE, useValue: false },
    // {
    //   provide: CONFIG, useValue: {
    //     send_page_view: false,
    //     allow_ad_personalization_signals: false,
    //     anonymize_ip: true
    //   }
    // },
    { provide: APP_INITIALIZER, useFactory: initializeAppConfig, deps: [ConfigService], multi: true },
    // ScreenTrackingService,
    // UserTrackingService,
    ToastService,
    NgbActiveModal,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LdYR_8kAAAAANKDLOYmuC7PMJUDnGb8IKJAtZay'

    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}

