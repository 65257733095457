<header class="text-center mt-5">
    <img src="assets/images/favicon.ico" alt="" width="40" height="40">
    <img src="assets/images/logo.svg" height="40" class="ps-3" alt="phylaxis">
</header>
<div class="d-flex w-100 mt-10 p-3 mx-auto flex-column">
    <!-- <nav class="navbar navbar-light ">
        <div class="container-fluid">
          <a class="navbar-brand ms-lg-5" href="#">
            <img src="assets/images/favicon.ico" alt="" width="40" height="40">
            <img src="assets/images/logo.svg" height="30" class="ps-3" alt="phylaxis">
          </a>
          
        </div>
      </nav> -->
   
    <div class="row align-items-center justify-content-center h-100">
        <div class="col-10 col-lg-4 col-md-6  bg-light pb-5">
            <div class="row align-items-center justify-content-center mt-3 mb-3">
                <div class="col-3 col-sm-2  col-md-2 col-lg-2 col-xl-2 mt-5 text-center">
                    <img src="assets/images/error-circle.svg" class="  w-75" alt="phylaxis">
                </div>
            </div>
            <div class="row align-items-center justify-content-center mt-3 mb-3">
                <div class="col-sm-12 col-md-8  col-lg-8 mt-5 text-center">

                    <!-- <h5 *ngIf="data">{{data.description}} </h5> -->
                    <h3>Sorry, the assessment is not available for this country</h3>

                    <button type="button" class="btn btn-primary mt-5 fw-bold" (click)="close()">Close</button>
                       
                </div>
            </div>
        </div>
    </div>

</div>