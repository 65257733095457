
import { BehaviorSubject, ObjectUnsubscribedError, Observable } from 'rxjs';
import { Amplify, Auth, } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { APP } from '../constants/app.constant';
import { CognitoJwtVerifier } from 'aws-jwt-verify';


export interface IUser {
  email?: string;
  phone_number: string;
  password: string;
  showPassword?: boolean;
  code?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {

  private _authentication = new BehaviorSubject<any>(false);
  private _user = new BehaviorSubject<any>(null);
  private _groups = new BehaviorSubject<any>([]);

  public readonly authentication$ = this._authentication.asObservable();//variable storing observable
  public readonly user$ = this._user.asObservable();
  public readonly groups$ = this._groups.asObservable();

  constructor(private storageSvc: StorageService,
    private userSvc: UserService) {
    Amplify.configure({
      Auth: environment.cognito,
    });
    this.cognitoJwtVerifier();
  }

  checkUser() {
    this.getUser().then((user: any) => {
      if (user) {
        if (user.signInUserSession && user.signInUserSession.accessToken
          && user.signInUserSession.accessToken.payload
          && user.signInUserSession.accessToken.payload["cognito:groups"]) {
          this._groups.next(user.signInUserSession.accessToken.payload["cognito:groups"]);
        }
        this.userSvc.getUser().subscribe((response: any) => {
          if (response && response.response && response.response.codes === APP.RESPONSE_STATUS.SUCCESS) {
            this._authentication.next(true);
            if (response && response.user.email_id !== APP.ANNONYMOUS.EMAIL) {
              this._user.next(response.user);
            }
          } else {
            this.createUser(user.attributes);
          }
        });
      }
    });
  }

  public currentAuthenticatedUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
    //__type: "UsernameExistsException"
  }

  public signUp(user: IUser): Promise<any> {
    return Auth.signUp({
      username: user.phone_number,
      password: user.password,
      attributes: {
        email: user.email,
        phone_number: user.phone_number,
        name: user.name
      }
    });
    //__type: "UsernameExistsException"
  }

  public confirmSignUp(user: any): Promise<any> {
    return Auth.confirmSignUp(user.phone_number, user.code);
  }

  public currentUserInfo(): Promise<any> {
    return Auth.currentUserInfo();
  }

  public resendSignUp(user: IUser): Promise<any> {
    return Auth.resendSignUp(user.phone_number);
  }

  public signIn(user: IUser): Promise<any> {
    return Auth.signIn(user.phone_number, user.password)
      .then((response) => {
        if (response.signInUserSession.accessToken.jwtToken) {
          this.storageSvc.setValue('JWT', response.signInUserSession.accessToken.jwtToken);
          //this.cognitoJwtVerifier();
        }
        return response;
      });
  }

  public signOut(): Promise<any> {
    return Auth.signOut()
      .then(() => {
        this.storageSvc.remove('JWT');
        this._authentication.next(false);
        this._user.next(null);
      });
  }

  // Send confirmation code to user's email
  public forgotPassword(user: IUser): Promise<any> {
    return Auth.forgotPassword(user.phone_number);

  }

  // Collect confirmation code and new password, then
  public forgotPasswordSubmit(user: any): Promise<any> {
    return Auth.forgotPasswordSubmit(user.phone_number, user.code, user.password)
      .then((response) => {
        return response;
      })
      ;
  }

  public getUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  // public updateUser(user: any): Promise<any> {
  //   return Auth.currentUserPoolUser()
  //     .then((response: any) => {
  //       return Auth.updateUserAttributes(user.name, user.phone_number)
  //         .then((response) => {
  //           return response;
  //         });
  //     });
  // }

  createUser(user: any) {
    const request = {
      "name": user.name,
      "phone_number": user.phone_number,
      "email_id": user.email,
      "reg_country_code": "ZAF_001",
      "user_identifier": user.sub

    }
    this.userSvc.addUser(request).subscribe((response => {
      this.checkUser();
    }))

  }

  public updateUser(user: any): Promise<any> {
    return Auth.updateUserAttributes(user.name, user.phone_number)
      .then((response) => {
        //this._authentication.next(true);
        return response;
      })
      ;
  }
  public deleteUser(): Promise<any> {
    return Auth.deleteUser();
  }

  async cognitoJwtVerifier() {
    const verifier = CognitoJwtVerifier.create({
      userPoolId: environment.cognito.userPoolId,
      tokenUse: "access",
      clientId: environment.cognito.userPoolWebClientId,
    });
    try {
      const jwt = this.storageSvc.getValue('JWT');
      if (jwt) {
        const payload = await verifier.verify(jwt);
        console.log("Token is valid. Payload:", payload);
        this.setTimeout(payload.exp);
        this.checkUser();
      }
    } catch {
      console.log("Token not valid!");
      this.storageSvc.remove('JWT');
      this._authentication.next(false);
    }
  }

  setTimeout(exp: any) {
    exp = exp - (200);
    setTimeout(this.refreshToken, exp);
    //this.refreshToken();
  }

  refreshToken() {
    console.log('refreshToken');
    Auth.currentSession()
      .then(data => {
        //console.log(data);
        //console.log(data.getRefreshToken().getToken());
        this.storageSvc.setValue('JWT', data.getRefreshToken().getToken());
        //data.getRefreshToken();
      })
      .catch(err => console.log(err));
  }

}