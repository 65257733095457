<main id="content" role="main" style="
    background-image: url(../../assets/images/Padel/background_1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  ">
    <!--HEADER-->
    <header id="header" class="navbar navbar-expand-lg navbar-end mb-0 navbar-sticky-top bg-white pb-0">
        <div class="row align-items-center justify-content-center mx-auto h-100 w-100 w-md-75">
            <div class="col-12 col-md-5 d-block text-start">
                <a class="navbar-brand" href="browse/landing" target="_blank">
                    <img src="../../../../assets/images/Browse_Img/logos/main-wbg.png" alt=""
                        style="height: auto; width: 13rem" height="auto" width="208" />
                </a>
            </div>
            <div class="col-12 col-md-7 d-none d-md-block text-end">
                <h5 class="text-primary text-right mb-0">
                    enabling <span class="text-success">Personalized, </span>
                    <span class="text-success">Prospective</span> and
                    <span class="text-success">Preventative</span>
                </h5>
                <h5 class="text-primary text-right mb-0">
                    healthcare as a <span class="text-success">Social Good</span>
                </h5>
            </div>
        </div>
        <div class="row align-items-center justify-content-center mx-auto h-100 w-100 bg-white d-none d-lg-block">
            <div class="mt-2 px-0 py-0">
                <div class="row mb-0 mx-0">
                    <div class="col-md-12 px-0 mx-0">
                        <div class="d-flex justify-content-between align-items-center breaking-news bg-white">
                            <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center py-2 px-1 news"
                                style="background-color: #f0ea23">
                                <span class="d-flex align-items-center">&nbsp;<b style="color: black">360 Padel League
                                        News</b></span>
                            </div>
                            <marquee class="news-scroll py-2 px-1" style="background-color: black" behavior="scroll"
                                direction="left" onmouseover="this.stop();" onmouseout="this.start();">

                                <span style="color: #f0ea23">The Gill 360 Padel League is officially underway!
                                </span>


                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">The Gauteng MEC for Sport & Education, Mr Matome Chiloane attends the opening of The Gill 360 Padel League, in association with Phylaxis.ai
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">The Gill 360 Padel League, in association with Phylaxis.ai sees massive support with coverage from SuperSport, SABC Sport, 947 and Die Beeld at the opening ceremony
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">The Globo Eagles’ Phylaxis Padel Pro, AJ Cullinan, misses matchday 1 due to illness
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">The Remate Braves and the Por Cuatro Smashers put in strong performances on the opening night of The Gill 360 Padel League, in association with Phylaxis.ai
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">Tiaan Erasmus (Por Cuatro Smashers) wins the first Phylaxis Padel Pro award
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">Zidane Adamjee and Nazeer Saley (Chiquita Jaguares) win the first Isorade Pair of the Round award
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">360 Padel SA is partnering with Gayle Padel Club to host a 40 team tournament in Pretoria on 07/09/2024
                                </span>
                            </marquee>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center justify-content-center mx-auto h-100 w-100 bg-white d-block d-lg-none">
            <div class="mt-2 px-0 py-0">
                <div class="row mb-0 mx-0">
                    <div class="col-md-12 px-0 mx-0">
                        <div class="d-flex justify-content-between align-items-center breaking-news bg-white">
                            <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center py-2 px-1 news"
                                style="background-color: #f0ea23">
                                <span class="d-flex align-items-center">&nbsp;<b style="color: black">News</b></span>
                            </div>
                            <marquee class="news-scroll py-2 px-1" style="background-color: black" behavior="scroll"
                                direction="left" onmouseover="this.stop();" onmouseout="this.start();">

                                <span style="color: #f0ea23">The Gill 360 Padel League is officially underway!
                                </span>


                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">The Gauteng MEC for Sport & Education, Mr Matome Chiloane attends the opening of The Gill 360 Padel League, in association with Phylaxis.ai
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">The Gill 360 Padel League, in association with Phylaxis.ai sees massive support with coverage from SuperSport, SABC Sport, 947 and Die Beeld at the opening ceremony
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">The Globo Eagles’ Phylaxis Padel Pro, AJ Cullinan, misses matchday 1 due to illness
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">The Remate Braves and the Por Cuatro Smashers put in strong performances on the opening night of The Gill 360 Padel League, in association with Phylaxis.ai
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">Tiaan Erasmus (Por Cuatro Smashers) wins the first Phylaxis Padel Pro award
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">Zidane Adamjee and Nazeer Saley (Chiquita Jaguares) win the first Isorade Pair of the Round award
                                </span>

                                <span><b class="mx-4" style="color: #f0ea23"> | </b></span>

                                <span style="color: #f0ea23">360 Padel SA is partnering with Gayle Padel Club to host a 40 team tournament in Pretoria on 07/09/2024
                                </span>
                            </marquee>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!--END HEADER-->

    <!--BANNER-->
    <div class="position-relative mt-13">
        <!-- <a (click)="open(register)" role="button"> -->
        <video autoplay [muted]="true" loop width="100%">
            <source src="https://test-padel-media-files.s3.eu-west-3.amazonaws.com/360padelleague_banner_video.mp4" type="video/mp4" />
        </video>
        <!-- </a> -->
    </div>
    <!--END BANNER-->

    <!--OPENING CARD-->
    <div class="container content-space-t-1 content-space-b-1">
        <!-- Heading -->
        <div class="card w-lg-100 text-center mx-lg-auto mx-auto"
            style="background-color: #dfff4f; vertical-align: middle">
            <p class="ps-4 pt-4 pe-4">
                Padel is an energising racket sport, which has taken the world by storm.
                It is the fastest growing sport in the world, and once you have played
                it, you will know why. Nicknamed "Tennis with walls" & "Squash in the
                sun", padel combines the best elements of Tennis and Squash and is
                suitable for all ages.
            </p>
        </div>
    </div>
    <!--END OPENING CARD-->

    <!--ABOUT 360 PADEL-->
    <div class="container content-space-t-1 content-space-b-1">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-6">
            <h1>The League</h1>
        </div>
        <div class="card w-lg-100 mx-lg-auto mx-auto">
            <div class="row align-items-md-center p-4">
                <div class="col-md-5 d-block align-items-center">
                    <img class="img-fluid rounded-2" src="../../assets/images/Padel/360Padel.png"
                        alt="Image Description" />
                </div>
                <!-- End Col -->

                <div class="col-md-7 align-items-top">
                    <!-- Blockquote -->
                    <figure class="pe-md-7">
                        <p>
                            360 Padel SA has developed a “first-of-its-kind” league to assist
                            in achieving the objectives:
                        </p>
                        <ul class="list-checked list-checked-bg-primary list-checked-sm mb-0">
                            <li class="list-checked-item">
                                To grow the game of Padel in South Africa by fostering
                                communities, building relationships, and stimulating interest in
                                the game.
                            </li>
                            <li class="list-checked-item">
                                To champion an all inclusive approach by bringing players of all
                                skill levels and backgrounds together.
                            </li>
                            <li class="list-checked-item">
                                By providing an exceptional professional playing experience to
                                all those involved. We aim to be THE MOST PROFESSIONAL AMATEUR
                                LEAGUE in South Africa.
                            </li>
                            <li class="list-checked-item">
                                To co-create a unique, high-impact product with like-minded
                                individuals.
                            </li>
                            <li class="list-checked-item mb-4">
                                To create value for brands that are associated with the league.
                            </li>
                        </ul>
                        <a role="button" href="https://www.instagram.com/p/C8aFDLeN4me/" target="_blank">Find out
                            more...<i class="bi bi-box-arrow-up-right"></i></a>
                    </figure>
                    <!-- End Blockquote -->
                </div>
                <!-- End Col -->
            </div>
            <!-- End Row -->
        </div>
    </div>
    <!--END ABOUT-->

    <!--FRANCHISES-->
    <div class="content-space-t-1 content-space-b-1 ">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-6">
            <h1>Franchises and Players</h1>
        </div>
        <div class="col-md-12 p-0 d-flex align-items-center justify-content-center mx-auto mb-5">
            <img class="img-fluid mx-auto w-md-50" src="../../assets/images/Padel/Padel_360_Teams.png"
                alt="Image Description" />
        </div>
        <!-- End Col -->
        <div class="col-md-12 p-0">
            <ngb-carousel class="d-block w-100 h-100 mx-auto">
                <ng-template ngbSlide>
                    <div class="card w-lg-100 mx-lg-auto mx-auto s-h-100 s-h-md-100 rounded-0" style="background-color: black">
                        <div class="row p-4 align-items-center justify-content-center h-100">
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid w-75 w-md-50 h-auto" src="../../assets/images/Padel/bajada.png"
                                    alt="Image Description" />
                            </div>
                            <!-- End Col -->

                            <div class="col-lg-4">
                                <!-- Blockquote -->
                                <figure class="align-items-center">
                                    <div class="mb-4 mt-0">
                                        <h2 style="color: #f28b2c">Bajada Bandits</h2>
                                    </div>

                                    <h3>
                                        <b style="color: #f0ea23">Franchise Owners:</b>
                                    </h3>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <p class="text-white">Ziad Mangera</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">M Aslam Rajah</p>
                                        </div>
                                    </div>
                                    <h3>
                                        <b style="color: #f0ea23">Players:</b>
                                    </h3>
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            <p class="text-white">Fayaad Cassim (C)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Egmond Van Heerden (Pro)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Asif Timol</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Faraaz Tayob</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Muhammad Suliman</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Suleman Lakhi</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Rashid Bhikha</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Riaaz Adamjee</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Adnan Adamjee</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Aslam Wadee</p>
                                        </div>
                                    </div>
                                </figure>
                                <!-- End Blockquote -->
                            </div>
                            <!-- End Col -->
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid" src="../../assets/images/Padel/shirts/bajada.png"
                                    alt="Image Description" />
                            </div>
                        </div>
                        <!-- End Row -->
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="card w-lg-100 mx-lg-auto mx-auto s-h-100 s-h-md-100 rounded-0" style="background-color: black">
                        <div class="row p-4 align-items-center justify-content-center h-100">
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid w-75 w-md-50 h-auto" src="../../assets/images/Padel/bendeja.png"
                                    alt="Image Description" />
                            </div>
                            <!-- End Col -->

                            <div class="col-lg-4">
                                <!-- Blockquote -->
                                <figure class="align-items-center">
                                    <div class="mb-4 mt-0">
                                        <h2 style="color: #e91c24">Bandeja Bulls</h2>
                                    </div>

                                    <h3>
                                        <b style="color: #f0ea23">Franchise Owners:</b>
                                    </h3>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <p class="text-white">Sheraz Motala</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Ashraf Mall</p>
                                        </div>
                                    </div>
                                    <h3>
                                        <b style="color: #f0ea23">Players:</b>
                                    </h3>
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            <p class="text-white">Riyaadh Motani (C)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Adam Van Harte (Pro)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Sarfraz Bismilla</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mujaahid Raja</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mohamed Joosub</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">M. Salim Gaibie</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zain Dendar</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Farzaad Dawood</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Talha Bamath</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mohammed Vardalia</p>
                                        </div>
                                    </div>
                                </figure>
                                <!-- End Blockquote -->
                            </div>
                            <!-- End Col -->
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid" src="../../assets/images/Padel/shirts/bendeja.png"
                                    alt="Image Description" />
                            </div>
                        </div>
                        <!-- End Row -->
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="card w-lg-100 mx-lg-auto mx-auto s-h-100 s-h-md-100 rounded-0" style="background-color: black">
                        <div class="row p-4 align-items-center justify-content-center h-100">
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid w-75 w-md-50 h-auto" src="../../assets/images/Padel/chiquita.png"
                                    alt="Image Description" />
                            </div>
                            <!-- End Col -->

                            <div class="col-lg-4">
                                <!-- Blockquote -->
                                <figure class="align-items-center">
                                    <div class="mb-4 mt-0">
                                        <h2 style="color: #f6e26c">Chiquita Jaguares</h2>
                                    </div>

                                    <h3>
                                        <b style="color: #f0ea23">Franchise Owners:</b>
                                    </h3>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <p class="text-white">M Fayaaz Cassim</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Yusuf Dasoo</p>
                                        </div>
                                    </div>
                                    <h3>
                                        <b style="color: #f0ea23">Players:</b>
                                    </h3>
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            <p class="text-white">Shaheen Amra (C)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Richard Ashforth (Pro)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Uzair Minty</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Justin Sher</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Nazeer Saley</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Suleman Jazbhai</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Feroz Essay</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Ihsaan Cassim</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Bilaal Bamjee</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zidane Adamjee</p>
                                        </div>
                                    </div>
                                </figure>
                                <!-- End Blockquote -->
                            </div>
                            <!-- End Col -->
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid" src="../../assets/images/Padel/shirts/chiquita.png"
                                    alt="Image Description" />
                            </div>
                        </div>
                        <!-- End Row -->
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="card w-lg-100 mx-lg-auto mx-auto s-h-100 s-h-md-100 rounded-0" style="background-color: black">
                        <div class="row p-4 align-items-center justify-content-center h-100">
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid w-75 w-md-50 h-auto" src="../../assets/images/Padel/Globo.png"
                                    alt="Image Description" />
                            </div>
                            <!-- End Col -->

                            <div class="col-lg-4">
                                <!-- Blockquote -->
                                <figure class="align-items-center">
                                    <div class="mb-4 mt-0">
                                        <h2 style="color: #cfae72">Globo Eagles</h2>
                                    </div>

                                    <h3>
                                        <b style="color: #f0ea23">Franchise Owners:</b>
                                    </h3>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <p class="text-white">Mebs Rahim</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Husein Thokan</p>
                                        </div>
                                    </div>
                                    <h3>
                                        <b style="color: #f0ea23">Players:</b>
                                    </h3>
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            <p class="text-white">Nikhil Joshi (C)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">AJ Cullinan (Pro)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Yusuf Wadee</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Muhammed Yusuf</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Amaan Tayob</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Shaahir Suleman</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mohamed Salojee</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Muhammed Patel</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Yazid Osmany</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Sameer Mahomed</p>
                                        </div>
                                    </div>
                                </figure>
                                <!-- End Blockquote -->
                            </div>
                            <!-- End Col -->
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid" src="../../assets/images/Padel/shirts/globo.png"
                                    alt="Image Description" />
                            </div>
                        </div>
                        <!-- End Row -->
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="card w-lg-100 mx-lg-auto mx-auto s-h-100 s-h-md-100 rounded-0" style="background-color: black">
                        <div class="row p-4 align-items-center justify-content-center h-100">
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid w-75 w-md-50 h-auto" src="../../assets/images/Padel/por_cuatro.png"
                                    alt="Image Description" />
                            </div>
                            <!-- End Col -->

                            <div class="col-lg-4">
                                <!-- Blockquote -->
                                <figure class="align-items-center">
                                    <div class="mb-4 mt-0">
                                        <h2 style="color: #1b73b2">Por Cuatro Smashers</h2>
                                    </div>

                                    <h3>
                                        <b style="color: #f0ea23">Franchise Owners:</b>
                                    </h3>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <p class="text-white">Riaz Amod</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Faadil Laher</p>
                                        </div>
                                    </div>
                                    <h3>
                                        <b style="color: #f0ea23">Players:</b>
                                    </h3>
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            <p class="text-white">Daniyaal Dasoo (C)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Tiaan Erasmus (Pro)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mohammed Majam</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Aasif Sayed</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mohammed Salojee</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zahir Mohamed</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zaid Majam</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zaahid Laher</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zaid Essop</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mohammed Dasoo</p>
                                        </div>
                                    </div>
                                </figure>
                                <!-- End Blockquote -->
                            </div>
                            <!-- End Col -->
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid" src="../../assets/images/Padel/shirts/por_curato.png"
                                    alt="Image Description" />
                            </div>
                        </div>
                        <!-- End Row -->
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="card w-lg-100 mx-lg-auto mx-auto s-h-100 s-h-md-100 rounded-0" style="background-color: black">
                        <div class="row p-4 align-items-center justify-content-center h-100">
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid w-75 w-md-50 h-auto" src="../../assets/images/Padel/remate.png"
                                    alt="Image Description" />
                            </div>
                            <!-- End Col -->

                            <div class="col-lg-4">
                                <!-- Blockquote -->
                                <figure class="align-items-center">
                                    <div class="mb-4 mt-0">
                                        <h2 style="color: #b0e02a">Remate Braves</h2>
                                    </div>

                                    <h3>
                                        <b style="color: #f0ea23">Franchise Owners:</b>
                                    </h3>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <p class="text-white">Nakul Bali</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Muhammad Wajoodeen</p>
                                        </div>
                                    </div>
                                    <h3>
                                        <b style="color: #f0ea23">Players:</b>
                                    </h3>
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            <p class="text-white">Mo Yusuf Ballim (C)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Chevaan Davids (Pro)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Yusf Ismail</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mateo Van Der Merwe</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Cam Rakow</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Jameel Nagdee</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Mohamed Jassat</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Burhaan-ud-deen Ismail</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Yaseen Hamid</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Nadeem Dhorat</p>
                                        </div>
                                    </div>
                                </figure>
                                <!-- End Blockquote -->
                            </div>
                            <!-- End Col -->
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid" src="../../assets/images/Padel/shirts/remate.png"
                                    alt="Image Description" />
                            </div>
                        </div>
                        <!-- End Row -->
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="card w-lg-100 mx-lg-auto mx-auto s-h-100 s-h-md-100 rounded-0" style="background-color: black">
                        <div class="row p-4 align-items-center justify-content-center h-100">
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid w-75 w-md-50 h-auto" src="../../assets/images/Padel/rulo.png"
                                    alt="Image Description" />
                            </div>
                            <!-- End Col -->

                            <div class="col-lg-4">
                                <!-- Blockquote -->
                                <figure class="align-items-center">
                                    <div class="mb-4 mt-0">
                                        <h2 style="color: #00aeef">Rulo Huntsmen</h2>
                                    </div>

                                    <h3>
                                        <b style="color: #f0ea23">Franchise Owners:</b>
                                    </h3>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <p class="text-white">Faheem Kaloo</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Umair Jassat</p>
                                        </div>
                                    </div>
                                    <h3>
                                        <b style="color: #f0ea23">Players:</b>
                                    </h3>
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            <p class="text-white">Ziyaad Kardamey (C)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Keagan Rooy (Pro)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Riaaz Gaibie</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Nabeel Vorajee</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Ahmed Moola</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zaheer Mayet</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Tameem Kardamey</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Nabeel Kaloo</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Muhammad Hoosain</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Muneer Bham</p>
                                        </div>
                                    </div>
                                </figure>
                                <!-- End Blockquote -->
                            </div>
                            <!-- End Col -->
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid" src="../../assets/images/Padel/shirts/rulo.png"
                                    alt="Image Description" />
                            </div>
                        </div>
                        <!-- End Row -->
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="card w-lg-100 mx-lg-auto mx-auto s-h-100 s-h-md-100 rounded-0" style="background-color: black">
                        <div class="row p-4 align-items-center justify-content-center h-100">
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid w-75 w-md-50 h-auto" src="../../assets/images/Padel/vibora.png"
                                    alt="Image Description" />
                            </div>
                            <!-- End Col -->

                            <div class="col-lg-4">
                                <!-- Blockquote -->
                                <figure class="align-items-center">
                                    <div class="mb-4 mt-0">
                                        <h2 style="color: #06a354">Vibora Vipers</h2>
                                    </div>

                                    <h3>
                                        <b style="color: #f0ea23">Franchise Owners:</b>
                                    </h3>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <p class="text-white">Waseem Mangera</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Ebrahim Akhalwaya</p>
                                        </div>
                                    </div>
                                    <h3>
                                        <b style="color: #f0ea23">Players:</b>
                                    </h3>
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            <p class="text-white">Muhammad Moosajee (C)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zaidy Patel (Pro)</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Muhammad Chothia</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Waseem Seedat</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Farhaan Sayanvala</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Abdurrahmaan Omar</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Muhammad Laher</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Zaid Jada</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Faraaz Ismail</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-white">Ahmed Gaffar</p>
                                        </div>
                                    </div>
                                </figure>
                                <!-- End Blockquote -->
                            </div>
                            <!-- End Col -->
                            <div class="col-lg-4 d-block align-items-center text-center">
                                <img class="img-fluid" src="../../assets/images/Padel/shirts/vibora.png"
                                    alt="Image Description" />
                            </div>
                        </div>
                        <!-- End Row -->
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>
    <!--END FRANCHISES-->

    <!-- Clients -->
    <div class="d-none d-lg-block container content-space-t-1 content-space-b-1">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-6">
            <h1>League Sponsors</h1>
        </div>
        <div class="row align-items-center">
            <div class="col-2 text-center py-3" style="border-left: 1px solid #f0ea23; border-right: 1px solid #f0ea23">
                <p style="color: #f0ea23">Title Sponsor</p>
                <img class="avatar avatar-lg avatar-4x3" src="../../assets/images/Padel/gill.png" alt="Logo" />
            </div>
            <!-- End Col -->

            <div class="col-2 text-center py-3">
                <p style="color: #f0ea23">Lead Sponsor</p>
                <img class="avatar avatar-xxl avatar-4x3"
                    src="../../assets/images/Browse_Img/logos/White-top-icon-clear-bg.png" alt="Logo" />
            </div>
            <!-- End Col -->

            <div class="col-8 text-center py-3">
                <div class="row text-center align-items-center" style="
            border-left: 1px solid #f0ea23;
            border-right: 1px solid #f0ea23;
          ">
                    <p class="text-center" style="color: #f0ea23">Associate Sponsors</p>
                    <div class="col text-center px-0">
                        <img class="avatar avatar-xl avatar-4x3" src="../../assets/images/Padel/Decathlon.png"
                            alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col text-center px-0">
                        <img class="avatar avatar-lg avatar-4x3" src="../../assets/images/Padel/Discover.png"
                            alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col text-center px-0">
                        <img class="avatar avatar-xl avatar-4x3" src="../../assets/images/Padel/2isorade.png"
                            alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col text-center px-0">
                        <img class="avatar avatar-lg avatar-4x3" src="../../assets/images/Padel/Plaega.png"
                            alt="Logo" />
                    </div>
                    <!-- End Col -->
                </div>
            </div>
        </div>
        <!-- End Row -->
    </div>
    <!-- End Clients -->

    <!--Sponsors mobile-->
    <div class="d-block d-lg-none container content-space-t-1 content-space-b-1">
        <div class="text-center mx-md-auto mb-6">
            <h1>League Sponsors</h1>
        </div>
        <div class="row align-items-center w-50 mx-auto">
            <div class="col-12 text-center py-3"
                style="border-top: 1px solid #f0ea23; border-bottom: 1px solid #f0ea23">
                <p style="color: #f0ea23">Title Sponsor</p>
                <img class="avatar avatar-lg avatar-4x3" src="../../assets/images/Padel/gill.png" alt="Logo" />
            </div>
            <!-- End Col -->

            <div class="col-12 text-center py-3">
                <p style="color: #f0ea23">Lead Sponsor</p>
                <img class="avatar avatar-xxl avatar-4x3"
                    src="../../assets/images/Browse_Img/logos/White-top-icon-clear-bg.png" alt="Logo" />
            </div>
            <!-- End Col -->

            <div class="col-12 text-center">
                <div class="row text-center align-items-center py-3" style="
            border-top: 1px solid #f0ea23;
            border-bottom: 1px solid #f0ea23;
          ">
                    <p class="text-center mb-0" style="color: #f0ea23">Associate Sponsors</p>
                    <div class="col text-center py-3">
                        <img class="avatar avatar-xl avatar-4x3" src="../../assets/images/Padel/Decathlon.png"
                            alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col text-center py-3">
                        <img class="avatar avatar-lg avatar-4x3" src="../../assets/images/Padel/Discover.png"
                            alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col text-center py-3">
                        <img class="avatar avatar-xl avatar-4x3" src="../../assets/images/Padel/isorade.png"
                            alt="Logo" />
                    </div>
                    <!-- End Col -->

                    <div class="col text-center py-3">
                        <img class="avatar avatar-lg avatar-4x3" src="../../assets/images/Padel/Plaega.png"
                            alt="Logo" />
                    </div>
                    <!-- End Col -->
                </div>
            </div>
        </div>
        <!-- End Row -->
    </div>
    <!-- End Clients -->

    <!--POINTS TABLE-->
    <div class="container content-space-t-1 content-space-b-1 w-100" #models id="models">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-4">
            <h1>Points Table</h1>
            <p class="text-center small fst-italic" style="color: #f0ea23;">Top 4 teams qualify for play-offs</p>
        </div>

        <!-- Table -->
        <div class="table-responsive w-lg-100 mx-lg-auto">
            <table class="table table-md table-light table-vertical-border-striped">
                <thead class="table-text-center">
                    <tr>
                        <th scope="col" class="w-auto">
                            <span class="d-block">#</span>
                        </th>

                        <th scope="col" class="w-auto">
                            <span class="d-block">Team</span>
                        </th>

                        <th scope="col" class="w-auto">
                            <span class="d-block">P</span>
                        </th>

                        <th scope="col" class="w-auto">
                            <span class="d-block">W</span>
                        </th>
                        <th scope="col" class="w-auto">
                            <span class="d-block">L</span>
                        </th>

                        <th scope="col" class="w-auto">
                            <span class="d-block">D</span>
                        </th>

                        <th scope="col" class="w-auto">
                            <span class="d-block">Pen</span>
                        </th>

                        <th scope="col" class="w-auto">
                            <span class="d-block">BP</span>
                        </th>

                        <th scope="col" class="w-auto">
                            <span class="d-block"><b>PTS</b></span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="align-middle">
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">
                            <div class="d-flex align-items-start justify-content-start text-start">
                                <img class="avatar avatar-sm" src="../../assets/images/Padel/remate.png"
                                    alt="Image Description" />
                                <div class="d-flex justify-content-start align-items-center align-middle mt-2">
                                    <h3 style="color: #b0e02a"
                                        class="d-none d-md-block justify-content-center my-auto ps-2">
                                        Remate Braves
                                    </h3>
                                    <p style="color: #b0e02a"
                                        class="d-block d-md-none justify-content-center my-auto ps-2 fw-bold text-start">
                                        Braves
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">3</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">3</td>
                        <td class="table-text-center"><b>12</b></td>
                    </tr>

                    <tr class="align-middle">
                        <td class="table-text-center">2</td>
                        <td class="table-text-center">
                            <div class="d-flex align-items-start justify-content-start text-start">
                                <img class="avatar avatar-sm" src="../../assets/images/Padel/por_cuatro.png"
                                    alt="Image Description" />
                                <div class="d-flex justify-content-start align-items-center align-middle mt-2">
                                    <h3 style="color: #1b73b2"
                                        class="d-none d-md-block justify-content-center my-auto ps-2">
                                        Por Cuatro Smashers
                                    </h3>
                                    <p style="color: #1b73b2"
                                        class="d-block d-md-none fw-bold justify-content-center my-auto ps-2">
                                        Smashers
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">3</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">3</td>
                        <td class="table-text-center"><b>12</b></td>
                    </tr>

                    <tr class="align-middle">
                        <td class="table-text-center">3</td>
                        <td class="table-text-center">
                            <div class="d-flex align-items-start justify-content-start text-start">
                                <img class="avatar avatar-sm" src="../../assets/images/Padel/bendeja.png"
                                    alt="Image Description" />
                                <div class="d-flex justify-content-start align-items-center align-middle mt-2">
                                    <h3 style="color: #e91c24"
                                        class="d-none d-md-block justify-content-center my-auto ps-2">
                                        Bandeja Bulls
                                    </h3>
                                    <p style="color: #e91c24"
                                        class="d-block d-md-none fw-bold justify-content-center my-auto ps-2">
                                        Bulls
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center"><b>9</b></td>
                    </tr>

                    <tr class="align-middle border-bottom border-dark border-2">
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">
                            <div class="d-flex align-items-start justify-content-start text-start">
                                <img class="avatar avatar-sm" src="../../assets/images/Padel/vibora.png"
                                    alt="Image Description" />
                                <div class="d-flex justify-content-start align-items-center align-middle mt-2">
                                    <h3 style="color: #06a354"
                                        class="d-none d-md-block justify-content-center my-auto ps-2">
                                        Vibora Vipers
                                    </h3>
                                    <p style="color: #06a354"
                                        class="d-block d-md-none fw-bold justify-content-center my-auto ps-2">
                                        Vipers
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center"><b>8</b></td>
                    </tr>

                    <tr class="align-middle">
                        <td class="table-text-center">5</td>
                        <td class="table-text-center">
                            <div class="d-flex align-items-start justify-content-start text-start">
                                <img class="avatar avatar-sm" src="../../assets/images/Padel/chiquita.png"
                                    alt="Image Description" />
                                <div class="d-flex justify-content-start align-items-center align-middle mt-2">
                                    <h3 style="color: #d3bd40"
                                        class="d-none d-md-block justify-content-center my-auto ps-2">
                                        Chiquita Jaguares
                                    </h3>
                                    <p style="color: #d3bd40"
                                        class="d-block d-md-none fw-bold justify-content-center my-auto ps-2">
                                        Jaguares
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center"><b>8</b></td>
                    </tr>

                    <tr class="align-middle">
                        <td class="table-text-center">6</td>
                        <td class="table-text-center">
                            <div class="d-flex align-items-start justify-content-start text-start">
                                <img class="avatar avatar-sm" src="../../assets/images/Padel/bajada.png"
                                    alt="Image Description" />
                                <div class="d-flex justify-content-start align-items-center align-middle mt-2">
                                    <h3 style="color: #f28b2c"
                                        class="d-none d-md-block justify-content-center my-auto ps-2">
                                        Bajada Bandits
                                    </h3>
                                    <p style="color: #f28b2c"
                                        class="d-block d-md-none fw-bold justify-content-center my-auto ps-2">
                                        Bandits
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">3</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center"><b>4</b></td>
                    </tr>

                    <tr class="align-middle">
                        <td class="table-text-center">7</td>
                        <td class="table-text-center">
                            <div class="d-flex align-items-start justify-content-start text-start">
                                <img class="avatar avatar-sm" src="../../assets/images/Padel/rulo.png"
                                    alt="Image Description" />
                                <div class="d-flex justify-content-start align-items-center align-middle mt-2">
                                    <h3 style="color: #00aeff"
                                        class="d-none d-md-block justify-content-center my-auto ps-2">
                                        Rulo Huntsmen
                                    </h3>
                                    <p style="color: #00aeff"
                                        class="d-block d-md-none fw-bold justify-content-center my-auto ps-2">
                                        Huntsmen
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">2</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center"><b>4</b></td>
                    </tr>

                    <tr class="align-middle">
                        <td class="table-text-center">8</td>
                        <td class="table-text-center">
                            <div class="d-flex align-items-start justify-content-start text-start">
                                <img class="avatar avatar-sm" src="../../assets/images/Padel/Globo.png"
                                    alt="Image Description" />
                                <div class="d-flex justify-content-start align-items-center align-middle mt-2">
                                    <h3 style="color: #cfae72"
                                        class="d-none d-md-block justify-content-center my-auto ps-2">
                                        Globo Eagles
                                    </h3>
                                    <p style="color: #cfae72"
                                        class="d-block d-md-none fw-bold justify-content-center my-auto ps-2">
                                        Eagles
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td class="table-text-center">4</td>
                        <td class="table-text-center">1</td>
                        <td class="table-text-center">3</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center">0</td>
                        <td class="table-text-center"><b>3</b></td>
                    </tr>

                    <tr class="align-middle">
                        <td class="table-text-center small" colspan="100%">P - Played, W - Won, L - Lost, D - Drawn, Pen
                            - Penalty Points, BP - Bonus Points, PTS - Points Total</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- End Table -->
    </div>
    <!-- END POINTS TABLE-->

    <!--FIXTURES AND RESULTS-->
    <div class="container content-space-t-1 content-space-b-1 w-100" #models id="models">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-4">
            <h1>Fixtures & Results</h1>
            <p class="text-white small">
                All fixtures to be played at the
                <a class="text-decoration-underline text-yellow"
                    href="https://www.google.com/maps?rlz=1C1YTUH_enZA1010ZA1010&gs_lcrp=EgZjaHJvbWUqDQgBEC4YrwEYxwEYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBggCEEUYOTINCAMQLhivARjHARiABDIJCAQQABgKGIAEMgcIBRAAGIAEMgwIBhAuGAoY1AIYgAQyDAgHEC4YChjUAhiABDIHCAgQABiABDIHCAkQABiABNIBCDQ0ODFqMGo3qAIAsAIA&um=1&ie=UTF-8&fb=1&gl=za&sa=X&geocode=KW8BIsF_DZUeMdNW9PAqTIZ7&daddr=5+Sunnyside+Rd,+Birnam,+Johannesburg,+2196"
                    role="button" target="_blank"><b>Racket + Ball club</b></a>
            </p>
        </div>

        <div class="p-md-4 w-100 js-nav-scroller hs-nav-scroller-horizontal mx-auto">
            <!--New Nav-->
            <nav ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false"
                class="nav nav-segment nav-fill mx-auto mb-5 w-sm-100">
                <ng-container [ngbNavItem]="1">
                    <a ngbNavLink>ROUND 1</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>ROUND 1 (14 AUG)</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/remate.png"
                                                                alt="Image Description" />

                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #b0e02a"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Remate Braves
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">3 - 1</h3>
                                                        <p class="card-text text-center ps-4 mb-0" style="font-size: small">
                                                            19:00 | Courts 1 & 8
                                                        </p>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 1 & 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/Globo.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 style="color: #cfae72"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Globo Eagles
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Chevaan Davids & Cam Rakow</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-1; 6-3; 6-2)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Nikhil Joshi & M Yusuf Cassim
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Mohammed Wajoodeen & Nadeem Dhorat</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-1; 6-3; 6-2)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Sameer Mohamed & Yazid Osmany
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Mohammed Jassat & Mateo van der Merwe</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>1 : 2</b> (6-0; 1-6; 6-7)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Husain Thoken & Amaan Tayob
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Mohammed Ballim & Burhaan Ismail</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-2; 6-2; 6-3)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Yusuf Wadee & Shaahir Suleman
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/chiquita.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #ebcf35"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Chiquita Jaguares
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">2 - 2</h3>
                                                        <p class="card-text text-center ps-4 mb-0" style="font-size: small">
                                                            19:00 | Courts 2 & 7
                                                        </p>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 2 & 7
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/vibora.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #06a354">
                                                                    Vibora Vipers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Richard Ashforth & Suleman Jazbhay</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-4; 6-2; 7-6)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Zaid Patel & Ebrahim Akhalwaya
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Shaheen Amra & Feroz Essay</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>0 : 3</b> (3-6; 4-6; 2-6)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Farhaan Sayanvala & Mohammed Moosajee
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Zidaan Adamjee & Nazeer Saley</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-2; 6-2; 6-0)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Mohammed Chothia & Waseem Mangera
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Justin Sher & Bilal Bhamjee</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>0 : 3</b> (4-6; 3-6; 4-6)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Mohammed Laher & Zaid Jada
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bendeja.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #e91c24"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Bandeja Bulls
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">2 - 1 (1 DRAW)</h3>
                                                        <p class="card-text text-center ps-4 mb-0" style="font-size: small">
                                                            19:00 | Courts 3 & 6
                                                        </p>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 3 & 6
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/rulo.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #00aeff">
                                                                    Rulo Huntsmen
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Adam Van Harte & Ashraf Mall</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>1 : 1</b> (4-6; 6-4; 1-3*)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Keagan Rooy & Ziyaad Kardamey
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Mohammed Joosub & Talha Bamath</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-3; 6-2; 6-3)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Umair Jassat & Muneer Bham
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Sarfraz Bismilla & Mujaahid Waja</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>1 : 2</b> (6-2; 2-6; 2-6)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Ahmed Moola & Mohammed Hoosein
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Riyaadh Motani & Farzaad Dawood</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-2; 6-3; 6-0)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Riaaz Gaibie & Tameem Kardamey
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/por_cuatro.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #1b73b2"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Por Cuatro Smashers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">3 - 1</h3>
                                                        <p class="card-text text-center ps-4 mb-0" style="font-size: small">
                                                            19:00 | Courts 4 & 5
                                                        </p>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 4 & 5
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bajada.png"
                                                                alt="Image Description" />
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f28b2c">
                                                                    Bajada Bandits
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Tiaan Erasmus & Mohammed Saloojee</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-1; 6-3; 6-2)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Egmond Van Heerden & Riaaz Adamjee
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Mohammed Dasoo & Faadil Laher</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (7-5; 6-2; 7-5)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Asif Timol & Faraaz Tayob
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Dan Dasoon & Zaid Majam</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>0 : 3</b> (6-7; 2-6; 1-6)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Fayaad Cassim & Mohammed Suliman
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Riaz Amod & Mohammed Majam</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center"><b>3 : 0</b> (6-3; 6-2; 6-2)</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Rashid Bhika & Adnan Adamjee
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                Zidane Adamjee & Nazeer Saley
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                Tiaan Erasmus
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-lg-center mt-4">
                                <div class="col-12">
                                    <p><span class="h4">Catch the week 1 highlites here: </span><a class="text-decoration-underline" href="https://www.discoversport.com/watch/b11f3644-6034-437b-9f1f-a188284fc74b/" target="_blank" role="button">Week 1 Highlites</a></p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="2">
                    <a ngbNavLink>ROUND 2</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>ROUND 2 (28 AUG)</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/remate.png"
                                                                alt="Image Description" />

                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #b0e02a"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Remate Braves
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/rulo.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 style="color: #00aeff"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Rulo Huntsmen
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/vibora.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #06a354"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Vibora Vipers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bendeja.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #e91c24">
                                                                    Bandeja Bulls
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/Globo.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #cfae72"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Globo Eagles
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/por_cuatro.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #1b73b2">
                                                                    Por Cuatro Smashers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/chiquita.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #f6e26c"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Chiquita Jagaures
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bajada.png"
                                                                alt="Image Description" />
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f28b2c">
                                                                    Bajada Bandits
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="3">
                    <a ngbNavLink>ROUND 3</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>ROUND 3 (4 SEPT)</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/chiquita.png"
                                                                alt="Image Description" />

                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #f6e26c"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Chiquita Jaguares
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/Globo.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 style="color: #cfae72"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Globo Eagles
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/rulo.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #00aeff"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Rulo Huntsmen
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bendeja.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f28b2c">
                                                                    Bajada Bandits
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/vibora.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #06a354"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Vibora Vipers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/por_cuatro.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #1b73b2">
                                                                    Por Cuatro Smashers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bendeja.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #e91c24"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Bandeja Bulls
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/remate.png"
                                                                alt="Image Description" />
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #b0e02a">
                                                                    Remate Braves
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="4">
                    <a ngbNavLink>ROUND 4</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>ROUND 4 (18 SEPT)</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/por_cuatro.png"
                                                                alt="Image Description" />

                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #1b73b2"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Por Cuatro Smashers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/remate.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 style="color: #b0e02a"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Remate Braves
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/Globo.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #cfae72"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Globo Eagles
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bendeja.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #e91c24">
                                                                    Bandeja Bulls
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/rulo.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #00aeff"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Rulo Huntsmen
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/chiquita.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f6e26c">
                                                                    Chiquita Jagaures
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/vibora.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #06a354"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Vibora Vipers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bajada.png"
                                                                alt="Image Description" />
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f28b2c">
                                                                    Bajada Bandits
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="5">
                    <a ngbNavLink>ROUND 5</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>ROUND 5 (2 OCT)</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bendeja.png"
                                                                alt="Image Description" />

                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #e91c24"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Bandeja Bulls
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/por_cuatro.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 style="color: #1b73b2"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Por Cuatro Smashers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bajada.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #f28b2c"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Bajada Bandits
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/Globo.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #cfae72">
                                                                    Globo Eagles
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/remate.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #b0e02a"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Remate Braves
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/chiquita.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f6e26c">
                                                                    Chiquita Jaguares
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/vibora.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #06a354"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Vibora Vipers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/rulo.png"
                                                                alt="Image Description" />
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #00aeff">
                                                                    Rulo Huntsmen
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="6">
                    <a ngbNavLink>ROUND 6</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>ROUND 6 (16 OCT)</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/vibora.png"
                                                                alt="Image Description" />

                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #06a354"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Vibora Vipers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/Globo.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 style="color: #cfae72"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Globo Eagles
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bajada.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #f28b2c"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Bajada Bandits
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/remate.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #b0e02a">
                                                                    Remate Braves
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bendeja.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #e91c24"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Bandeja Bulls
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/chiquita.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f6e26c">
                                                                    Chiquita Jaguares
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/por_cuatro.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #1b73b2"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Por Cuatro Smashers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/rulo.png"
                                                                alt="Image Description" />
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #00aeff">
                                                                    Rulo Huntsmen
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="7">
                    <a ngbNavLink>ROUND 7</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>ROUND 7 (30 OCT)</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/vibora.png"
                                                                alt="Image Description" />

                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #06a354"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Vibora Vipers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/remate.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 style="color: #b0e02a"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Remate Braves
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bendeja.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #e91c24"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Bandeja Bulls
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/bajada.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f28b2c">
                                                                    Bajada Bandits
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/por_cuatro.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #1b73b2"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Por Cuatro Smashers
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/chiquita.png"
                                                                alt="Image Description" />

                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #f6e26c">
                                                                    Chiquita Jaguares
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/rulo.png"
                                                                alt="Image Description" />
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: #00aeff"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    Rulo Huntsmen
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            20:30 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <img class="avatar avatar-sm"
                                                                src="../../assets/images/Padel/Globo.png"
                                                                alt="Image Description" />
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: #cfae72">
                                                                    Globo Eagles
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="8">
                    <a ngbNavLink>PLAYOFFS</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>PLAYOFFS</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <i class="bi bi-shield-fill h2"></i>
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: black"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    TBD
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <i class="bi bi-shield-fill h2"></i>
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: black">
                                                                    TBD
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <i class="bi bi-shield-fill h2"></i>
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: black"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    TBD
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 5 - 8
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <i class="bi bi-shield-fill h2"></i>
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: black">
                                                                    TBD
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="9">
                    <a ngbNavLink>FINALS</a>
                    <ng-template ngbNavContent>
                        <div class="card w-lg-100 text-center mx-lg-auto mx-auto p-4">
                            <h2>FINALS</h2>
                            <div class="d-grid gap-10">
                                <div class="d-grid gap-5">
                                    <!-- Accordion -->
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle class="px-0">
                                                <div class="row w-100 py-2 py-md-0">
                                                    <div class="col-12 col-md-4 text-sm-center text-md-start px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start">
                                                            <i class="bi bi-shield-fill h2"></i>
                                                            <div
                                                                class="d-flex justify-content-start align-items-center">
                                                                <h3 style="color: black"
                                                                    class="justify-content-center my-auto ps-2">
                                                                    TBD
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 my-auto">
                                                        <h3 class="ps-4 text-center my-auto">vs</h3>
                                                        <p class="card-text text-center ps-4" style="font-size: small">
                                                            19:00 | Courts 1 - 4
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-0">
                                                        <div
                                                            class="d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-end pe-2">
                                                            <i class="bi bi-shield-fill h2"></i>
                                                            <div class="d-flex justify-content-end align-items-center">
                                                                <h3 class="justify-content-center my-auto ps-2"
                                                                    style="color: black">
                                                                    TBD
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 1</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 1
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 2</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 2
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3"
                                                    style="border-bottom: 1px solid rgba(33, 50, 91, 0.1);">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 3</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 3
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row px-2 pt-3">
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-start">Pair 4</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center">vs</p>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p class="text-center text-md-end pe-md-4">
                                                            Pair 4
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <!-- End Accordion -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Isorade</b> Pair of the round</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Decathlon</b> Most Valuable Player</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center align-middle mt-2">
                                        <div class="flex-shrink-0 mt-3">
                                            <a class="avatar">
                                                <h2><i class="bi bi-file-person"></i></h2>
                                            </a>
                                        </div>

                                        <div class="flex-grow-1 ms-3 text-start">
                                            <p class="card-link link-dark justify-content-center my-auto">
                                                TBD
                                            </p>
                                            <p class="card-text small"><b>Phylaxis</b> Pro-player of the round</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </nav>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>
            <!--End New Nav-->

            <!-- End Nav -->
        </div>
    </div>
    <!--END FIXTURES-->

    <!-- Subscribe -->
    <div class="container content-space-1">
        <div class="w-100 text-center mx-md-auto">
            <div class="row justify-content-lg-between">
                <!-- Heading -->
                <div class="mb-5">
                    <h1>Contact Us</h1>
                </div>
                <!-- End Heading -->
                <ul class="list-inline list-separator mb-3">
                    <li class="list-inline-item">
                        <h3 style="color: #f0ea23;"><a style="color: #f0ea23;"
                                href="mailto:riaaz@360padelsa.co.za" target="_blank"
                                class="text-decoration-underline">info&#64;360padelsa.co.za</a></h3>
                    </li>
                    <li class="list-inline-item">
                        <h3 style="color: #f0ea23;">Mohammed Majam: 082 826 0180</h3>
                    </li>
                    <li class="list-inline-item">
                        <h3 style="color: #f0ea23;">Riaaz Gaibie: 082 256 6927</h3>
                    </li>
                    <li class="list-inline-item">
                        <h3 style="color: #f0ea23;">Sarfraz Bismilla: 083 557 5939</h3>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://www.instagram.com/360padelsa/" target="_blank" style="color: #f0ea23;"><i
                                class="bi bi-instagram h3" style="color: #f0ea23;"></i> &#64;360padelsa</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- End Subscribe -->

    <!--FOOTER-->
    <div class="bg-dark">
        <div class="container">
            <div class="w-md-85 text-center mx-lg-auto py-3">
                <p class="text-white-50 small">
                    &copy;
                    <a target="blank" href="https://www.phithos.com/"><b class="text-white-50">Phithos
                            Technologies</b></a>. All rights reserved.
                </p>
                <p class="text-white-50 small">
                    When you visit or interact with our sites, services or tools, we or
                    our authorised service providers may use cookies for storing
                    information to help provide you with a better, faster and safer
                    experience and for marketing purposes.
                </p>
            </div>
        </div>
    </div>
    <!--END FOOTER-->
</main>
<!--End CONTENT-->

<app-loader></app-loader>