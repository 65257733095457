export const environment = {
  production: true,
  baseUrlV2: 'https://ueax8fikrc.execute-api.eu-west-3.amazonaws.com/test/d2u',
  baseUrlAWSApi: 'https://xc8qt55s3j.execute-api.af-south-1.amazonaws.com/test/d2u',
  baseUrlS3: 'https://test-phylaxis-media-files.s3.eu-west-3.amazonaws.com',
  xAuthToken: '808362a8-f742-d595-5e35-b7bcc6ab777e',
  redirectPath: 'https://testzaf.phylaxis.ai',
  defaultUrl: 'browse',
  featureToggles: {
    useConfigApi: false,
    enableWithCredentials: false,
    enableLocation: true
  },
  config: {
    webUrl: 'https://test.phylaxis.ai',
    adminWebUrl: 'https://test.phylaxis.ai',
  },
  idleTimeinSeconds: 300,
  countDownTimeinSeconds: 60,
  channel_type: 'WEB',
  otpVerificationCountDownTimeInSeconds: 120,
  recaptchaKey: '6Lfd_asgAAAAAFxTxQ4jsURYPzWU0JcUE0SyKAR1',
  firebase: {
    apiKey: 'AIzaSyCpwyAzgu2azDmW79y0e2KAzALdAjFDz-g',
    authDomain: 'phylaxis-dev.firebaseapp.com',
    projectId: 'phylaxis-dev',
    storageBucket: 'phylaxis-dev.appspot.com',
    messagingSenderId: '500116941423',
    appId: '1:500116941423:web:0936e69101f9e0595c7b9a',
    measurementId: 'G-X4J68EQZ5J',
  },
  cognito: {
    userPoolId: 'eu-west-3_VwRsdfOUb',
    userPoolWebClientId: '6c7t2gjvkul3jvu57b5eq5j865',
    region: 'eu-west-3',
    identityPoolId: 'eu-west-3:58cfe715-099e-4a09-ae29-b435b6571e25',
  },
  payments: {
    PAYU: {
      key: 'iEPFuU',
      action: 'https://test.payu.in/_payment',
    },
  },
  bitly: {
    token: 'be68683e1331090fb778bb589ece9fda7d53bd57',
    api: 'https://api-ssl.bitly.com',
  },
  rebrandly: {
    token: '9475ffc54b18464d84b9c136435b729a',
    workspace: '6e461de534ed4a6ea1b1f08b15a12991',
    api: 'https://api.rebrandly.com/v1/links',
  },

  encryptionKey: {
    key: 'trl1a6a'
  },
  country: {
    name: "South Africa",
    country_calling_code: "+27",
    country_id: "ZAF_001"
  }
};

