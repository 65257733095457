import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public anonymousUserCache = new Map<string, Observable<any>>();
  public userCache = new Map<string, Observable<any>>();

  constructor(
    private httClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router) { }

  setValue(key: string, data: string) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(key, data);
    }
  }

  getValue(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem(key);
    }
  }

  // getAnonymousUser() {
  //   // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/anonymous`);
  //   return this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/user/anonymous`);
  // }

  public getAnonymousUser(): any {
    if (this.anonymousUserCache.get('')) {
      return this.anonymousUserCache.get('ANONYMOUS_USER');
    } else {
      const anonymousUserCacheRequest = this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/user/anonymous`).pipe(shareReplay(1));
      this.anonymousUserCache.set('ANONYMOUS_USER', anonymousUserCacheRequest)
      return anonymousUserCacheRequest;
    }
  }

  getUser() {
    if (this.userCache.get('')) {
      return this.userCache.get('USER');
    } else {
      const userCacheRequest = this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/user`).pipe(shareReplay(1));
      this.anonymousUserCache.set('USER', userCacheRequest)
      return userCacheRequest;
    }
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/user`);
  }

  addUser(data: any) {
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/user`, data);
    return this.httClient.post<any>(`${environment.baseUrlAWSApi}/api/user`, data);
  }

  getOrders() {
    //  return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/orders`);
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/user/orders`);
  }

  deleteUser(request: any) {
    return this.httClient.put<any>(`${environment.baseUrlAWSApi}/user`, request);
  }

  updateUser(request: any) {
    // return this.httClient.put<any>(`${environment.baseUrlJavaApi}/user`, request);
    return this.httClient.put<any>(`${environment.baseUrlAWSApi}/api/user`, request);
  }

  getCountryList() {
    return this.httClient.get<any>(`${environment.baseUrlV2}/get-country-list`);
  }

  getMyRewards(orderId?: any) {
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/rewards`);
    if (orderId) {
      return this.httClient.get<any>(`${environment.baseUrlAWSApi}/user/reward?orderId=` + orderId);
    } else {
      return this.httClient.get<any>(`${environment.baseUrlAWSApi}/user/reward`);
    }
  }

  getMyEarnback() {
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/earnback`);
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/user/reward/earnback`);
  }

  getDashboard() {
    //return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/dashboard`);
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/user/dashboard`);
  }

  getCommunicationTemplate(name: any) {
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/comms/templates?name=${name}`);
  }

  validateUpa(data: any) {
    //return this.httClient.post<any>(`${environment.baseUrlJavaApi}/user/validate-vpa`, data);
    return this.httClient.post<any>(`${environment.baseUrlAWSApi}/user/validate-vpa`, data);
  }

  getOrderStatus(id: any) {
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/user/payout-status/${id}`);

    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/payout-status/${id}`);
  }

  transfer(data: any) {
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/user/payout`, data);
    return this.httClient.post<any>(`${environment.baseUrlAWSApi}/user/payout`, data);
  }


  getMyPayout() {
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/payout`);
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/user/payout`);
  }
  getEarnbackTemplate(rewarddetailId: any) {
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/earnback-template/${rewarddetailId}`);
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/user/earnback-template/${rewarddetailId}`);

  }
  createEarnbackTemplate(data: any) {
    // return this.httClient.post<any>(`${environment.baseUrlJavaApi}/user/create/earnback-template`, data);
    return this.httClient.post<any>(`${environment.baseUrlAWSApi}/api/user/create/earnback-template`, data);

  }
  getPromoteAndEarn() {
    // return this.httClient.get<any>(`${environment.baseUrlJavaApi}/user/promote-and-earn`);
    return this.httClient.get<any>(`${environment.baseUrlAWSApi}/api/user/promote-and-earn`);
  }

}
