<!-- Header -->
<header class="navbar navbar-expand-lg navbar-end mb-3 navbar-sticky-top bg-white">
  <div class="container">
    <div class="navbar-nav-wrap">
      <div class="navbar-brand-wrapper">
        <!-- Logo -->
        <a class="navbar-brand" href="browse/landing">
          <img src="../../../../assets/images/Browse_Img/logos/main-wbg.png" alt="" style="height: 50%; width: 13rem" />
        </a>
        <!-- End Logo -->
      </div>

      <!-- Toggle -->
      <button type="button" class="navbar-toggler ms-auto" (click)="isMenuCollapsed = !isMenuCollapsed">
        <span class="navbar-toggler-default">
          <i class="bi-list"></i>
        </span>
        <span class="navbar-toggler-toggled">
          <i class="bi-x"></i>
        </span>
      </button>

      <nav [ngbCollapse]="isMenuCollapsed" class="navbar-nav-wrap-col collapse navbar-collapse" id="navbarNavMenu">
        <!-- Navbar -->
        <ul ngbNav class="navbar-nav" [(activeId)]="active">
          <!-- Landings -->
          <li class="hs-has-mega-menu nav-item" [ngbNavItem]="1">
            <a ngbNavLink id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link fw-bold"
              routerLink="/browse/landing" role="button" (click)="isMenuCollapsed = !isMenuCollapsed">Home</a>
          </li>
          <!-- End Landings -->

          <li ngbDropdown class="nav-item d-inline-block" [ngbNavItem]="2">
            <a class="hs-mega-menu-invoker dropdown-toggle nav-link fw-bold"  id="loginDropDown"
              role="button" ngbDropdownToggle>
              Phylaxis
            </a>
            <ul ngbDropdownMenu aria-labelledby="loginDropDown" class="hs-sub-menu dropdown-menu">
              <li>
                <a class="dropdown-item" ngbDropDownItem [routerLink]="['/', 'browse', 'about']"
                  (click)="isMenuCollapsed = !isMenuCollapsed">About</a>
              </li>
              <li>
                <a class="dropdown-item" ngbDropDownItem [routerLink]="['/', 'browse', 'platform']"
                  (click)="isMenuCollapsed = !isMenuCollapsed">The Platform</a>
              </li>
              <li>
                <a class="dropdown-item" ngbDropDownItem [routerLink]="['/', 'browse', 'publications']"
                  (click)="isMenuCollapsed = !isMenuCollapsed">Publications</a>
              </li>
              <li>
                <a class="dropdown-item" ngbDropDownItem [routerLink]="['/', 'browse', 'partners']"
                  (click)="isMenuCollapsed = !isMenuCollapsed">Partners</a>
              </li>
              <li>
                <a class="dropdown-item" ngbDropDownItem [routerLink]="['/', 'browse', 'media']"
                  (click)="isMenuCollapsed = !isMenuCollapsed">News & Media</a>
              </li>
              <li>
                <a class="dropdown-item" ngbDropDownItem [routerLink]="['/', 'browse', 'blog']"
                  (click)="isMenuCollapsed = !isMenuCollapsed">Blog</a>
              </li>
            </ul>
          </li>

          <!-- Company -->
          <!-- <li class="hs-has-sub-menu nav-item">
                  <a id="companyMegaMenu" class="hs-mega-menu-invoker nav-link" href="#" role="button" aria-expanded="false">Contact Us</a> -->
          <!-- End Company -->

          <!-- Pages -->
          <!-- <li [ngbNavItem]="7">
            <a ngbNavLink id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link fw-bold"
              routerLink="/browse/assessments/SA_001" role="button"
              (click)="isMenuCollapsed = !isMenuCollapsed">Individuals</a>
          </li> -->
          <li [ngbNavItem]="3">
            <a ngbNavLink id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link fw-bold" routerLink="/browse/ngos"
              role="button" (click)="isMenuCollapsed = !isMenuCollapsed">NGOs/NPOs/Clinics</a>
          </li>

          <li [ngbNavItem]="4">
            <a ngbNavLink id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link fw-bold"
              routerLink="/browse/government" role="button" (click)="isMenuCollapsed = !isMenuCollapsed">Government</a>
          </li>

          <li [ngbNavItem]="5">
            <a ngbNavLink id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link fw-bold"
              routerLink="/browse/private-sector" role="button" (click)="isMenuCollapsed = !isMenuCollapsed">Private-Sector</a>
          </li>
          <li [ngbNavItem]="6">
            <a ngbNavLink id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link fw-bold"
              routerLink="/browse/innovators" role="button" (click)="isMenuCollapsed = !isMenuCollapsed">Innovators</a>
          </li>

          <li [ngbNavItem]="7">
            <a ngbNavLink id="landingsMegaMenu" class="hs-mega-menu-invoker nav-link fw-bold"
              routerLink="/browse/contact" role="button" (click)="isMenuCollapsed = !isMenuCollapsed">Contact Us</a>
          </li>

          <!-- <li class="hs-has-mega-menu nav-item" *ngIf="user && myAccountButton">
            <img role="button" src="assets/images/Browse_Img/svg/person-fill.svg" [title]="isAuthenticated ? 'My account' : 'Login'"
              height="30" class="ps-3 mt-1" alt="phylaxis" (click)="openUser()" />
          </li> -->

          <!-- <li>
            <div ngbDropdown class="nav-itemd-inline-block">
              <a *ngIf="user && loginButton" class="btn btn-soft-dark btn-sm ms-3" (click)="signOut()">Log out</a>
              <a ngbDropdownToggle type="button" class="hs-mega-menu-invoker btn btn-sm btn-primary" *ngIf="!user"
                id="loginDropDown">Login</a>
                <ul ngbDropdownMenu aria-labelledby="loginDropDown" class="hs-sub-menu dropdown-menu">
                  <li>
                    <a class="dropdown-item" ngbDropDownItem [routerLink]="['/', 'auth', 'login']"
                      (click)="isMenuCollapsed = !isMenuCollapsed">Individual User </a>
                  </li>
                  <li>
                    <a class="dropdown-item" ngbDropDownItem  (click)="adminLogin()" href=""
                      (click)="isMenuCollapsed = !isMenuCollapsed">Corporate User</a>
                  </li>
                </ul>
            </div>
          </li> -->
        </ul>
        <!-- End Navbar -->
      </nav>
    </div>
  </div>
</header>
<!-- End Header -->