import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  setValue(key: string, data: string) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(key, data);
    }
  }

  getValue(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem(key);
    }
  }

  remove(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.removeItem(key);
    }
  }

  clear(): any {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.clear();
    }
  }
}
